import { useEffect, useState } from 'react';
function usePersistedState(key, initialState) {
    var _a = useState(function () {
        try {
            var storageValue = localStorage.getItem(key);
            if (storageValue && storageValue !== undefined) {
                return storageValue !== undefined ? JSON.parse(storageValue) : null;
            }
            return initialState;
        }
        catch (error) { }
    }), state = _a[0], setState = _a[1];
    useEffect(function () {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
}
export default usePersistedState;
