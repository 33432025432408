var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AlertComponent, { ENUM_RESULT_ALERT_BUTTON, } from '@components/AlertComponent';
import { BoxComponent } from '@components/BoxComponent';
import CardProductBasketComponent from '@components/CardProductBasketComponent';
import { useCart } from '@context/Cart';
import { useLoadingRequest } from '@context/LoadingRequest';
import { useOrder } from '@context/Order';
import { useStore } from '@context/Store';
import useFetchSpotsOfMember from '@hooks/fetch/useFetchSpotsOfMember';
import { useCreatePaymentOrderMutation } from '@hooks/mutations/useCreatePaymentOrderMutation';
import useDisclosure from '@hooks/useDisclosure';
import useHandleError from '@hooks/useHandleError';
import { useToast } from '@hooks/useToast';
import { Box, Divider, FormControl, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableRow, Typography, } from '@mui/material';
import api from '@services/api';
import { closeOrder } from '@services/api/close-order';
import { generateOrderInPdf } from '@services/api/generate-order-in-pdf';
import { printVoucher } from '@services/api/print-voucher';
import { ENUM_DISTRIBUTION_CHANNELS_IDENTIFICATION } from '@services/distributionChannels/types/IDistributionChannel';
import { ENUM_SLUG_ORDER_TYPE } from '@services/orderTypes/types/IOrderType';
import imageEmptyBox from '@statics/img/png/empty-box.png';
import deleteKeysEmptiesOfObject from '@utils/deleteKeysEmptiesOfObject';
import { memo, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { Button, Input, SelectPicker, Text } from 'rsuite';
import ContainerOrderTypes from '../ContainerOrderTypes';
import InformationAndPaymentContainer from './InformationAndPaymentContainer';
var ENUM_CREATE_ORDER_STEP;
(function (ENUM_CREATE_ORDER_STEP) {
    ENUM_CREATE_ORDER_STEP[ENUM_CREATE_ORDER_STEP["STEP_ONE"] = 0] = "STEP_ONE";
    ENUM_CREATE_ORDER_STEP[ENUM_CREATE_ORDER_STEP["STEP_TWO"] = 1] = "STEP_TWO";
    ENUM_CREATE_ORDER_STEP[ENUM_CREATE_ORDER_STEP["STEP_THREE"] = 2] = "STEP_THREE";
})(ENUM_CREATE_ORDER_STEP || (ENUM_CREATE_ORDER_STEP = {}));
function CreateOrderContainer(_a) {
    var _this = this;
    var _b, _c, _d, _e, _f, _g, _h;
    var onSuccessCreateOrder = _a.onSuccessCreateOrder;
    var _j = useStore(), storeSelected = _j.storeSelected, orderTypes = _j.orderTypes, configurations = _j.configurations;
    var handleErrors = useHandleError();
    var loadingRequest = useLoadingRequest();
    var _k = useCart(), productsCart = _k.productsCart, calculates = _k.calculates, clearAllProductToCart = _k.clearAllProductToCart, itemsQuantity = _k.itemsQuantity;
    var _l = useOrder(), orderCurrency = _l.currency, orderSpot = _l.spot, orderCatalog = _l.catalog, havePaymentsOrder = _l.havePaymentsOrder, sumAmountChargedPayments = _l.sumAmountChargedPayments, onClearPaymentData = _l.onClearPaymentData, orderPayments = _l.orderPayments, onSetCommentOrder = _l.onSetCommentOrder, commentOrder = _l.commentOrder, clientOrder = _l.clientOrder, onSetClientOrder = _l.onSetClientOrder, clientAddressOrder = _l.clientAddressOrder, onSetClientAddressOrder = _l.onSetClientAddressOrder;
    var query = useSearchParams()[0];
    var slugOrderType = query.get('ot');
    var toast = useToast();
    var _m = useOrder(), spotOrder = _m.spot, onSpotSelected = _m.onSpotSelected;
    var _o = useState(0), stepCreateOrder = _o[0], setStepCreateOrder = _o[1];
    var _p = useState(false), isPossiblePayment = _p[0], setIsPossiblePayment = _p[1];
    var disclosureCleanCart = useDisclosure();
    var disclosureEmitVoucher = useDisclosure(!!(storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid) && configurations
        ? (_b = configurations[storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid][0]) === null || _b === void 0 ? void 0 : _b.emit_voucher
        : false);
    var handleChangeSpot = function (value) {
        var find = spots.find(function (spot) { return spot.spot_uuid === value; });
        if (!find)
            return;
        onSpotSelected(find.spot);
    };
    var _q = useFetchSpotsOfMember(), spots = _q.data, isLoadingSpots = _q.isLoading;
    function clearAllOrderCreate() {
        onClearPaymentData();
        clearAllProductToCart();
        onSetCommentOrder('');
        onSetClientOrder(null);
        onSetClientAddressOrder(null);
    }
    var closeOrderFn = useMutation({
        mutationFn: closeOrder,
        onError: handleErrors,
        onSuccess: function () {
            toast.success({ description: 'Orden finalizada con éxito.' });
            clearAllOrderCreate();
        },
        onMutate: function () {
            loadingRequest.showLoading('Finalizando orden...');
        },
        onSettled: function () {
            loadingRequest.hiddenLoading();
        },
    }).mutateAsync;
    var printVoucherFn = useMutation({
        mutationFn: printVoucher,
        onError: function (error, variables) {
            handleErrors(error);
            generateOrderInPdf({
                orderCode: variables.orderCode,
                storeId: variables.storeId,
            });
        },
        onMutate: function () {
            loadingRequest.showLoading('Imprimiendo voucher...');
        },
        onSettled: loadingRequest.hiddenLoading,
    }).mutateAsync;
    var mutationCreateOrder = useMutation(function (finallyOrder) { return __awaiter(_this, void 0, void 0, function () {
        var orderTypeStore, items, response;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    loadingRequest.showLoading('Creando orden...');
                    orderTypeStore = orderTypes.find(function (o) { var _a; return ((_a = o.order_type) === null || _a === void 0 ? void 0 : _a.slug) === slugOrderType; });
                    items = productsCart.map(function (p) { return ({
                        id: p.uuid,
                        quantity: p.quantity,
                        comment: p.comment,
                    }); });
                    if (!orderTypeStore)
                        return [2 /*return*/];
                    return [4 /*yield*/, api.post("/v1/stores/".concat(storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid, "/orders"), {
                            currency_id: orderCurrency === null || orderCurrency === void 0 ? void 0 : orderCurrency.uuid,
                            order_type_id: orderTypeStore.order_type_uuid,
                            spot_id: orderSpot === null || orderSpot === void 0 ? void 0 : orderSpot.uuid,
                            catalog_id: orderCatalog === null || orderCatalog === void 0 ? void 0 : orderCatalog.uuid,
                            items: items.map(function (i) { return deleteKeysEmptiesOfObject(i); }),
                            comment: commentOrder,
                            client_id: (_a = clientOrder === null || clientOrder === void 0 ? void 0 : clientOrder.id) !== null && _a !== void 0 ? _a : null,
                            address_id: (_b = clientAddressOrder === null || clientAddressOrder === void 0 ? void 0 : clientAddressOrder.id) !== null && _b !== void 0 ? _b : null,
                        }, {
                            headers: {
                                'x-channel': ENUM_DISTRIBUTION_CHANNELS_IDENTIFICATION.UUID_POINT_OF_SALE,
                            },
                        })];
                case 1:
                    response = _c.sent();
                    return [2 /*return*/, {
                            data: response.data,
                            finallyOrder: finallyOrder,
                        }];
            }
        });
    }); }, {
        onError: handleErrors,
        onSettled: function () {
            loadingRequest.hiddenLoading();
        },
        onSuccess: function (_a) {
            var data = _a.data, finallyOrder = _a.finallyOrder;
            return __awaiter(_this, void 0, void 0, function () {
                var _i, orderPayments_1, payment;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            toast.success({ description: 'Orden creada correctamente' });
                            if (!(havePaymentsOrder && orderPayments.length > 0)) return [3 /*break*/, 4];
                            _i = 0, orderPayments_1 = orderPayments;
                            _b.label = 1;
                        case 1:
                            if (!(_i < orderPayments_1.length)) return [3 /*break*/, 4];
                            payment = orderPayments_1[_i];
                            return [4 /*yield*/, mutationCreatePayment.mutateAsync({
                                    orderCode: data.order_code,
                                    payment: payment,
                                })];
                        case 2:
                            _b.sent();
                            _b.label = 3;
                        case 3:
                            _i++;
                            return [3 /*break*/, 1];
                        case 4:
                            if (!finallyOrder) return [3 /*break*/, 6];
                            return [4 /*yield*/, closeOrderFn({ orderCode: data.order_code })];
                        case 5:
                            _b.sent();
                            _b.label = 6;
                        case 6:
                            if (disclosureEmitVoucher.isOpen) {
                                printVoucherFn({
                                    orderCode: data.order_code,
                                    storeId: storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid,
                                });
                            }
                            clearAllOrderCreate();
                            setStepCreateOrder(ENUM_CREATE_ORDER_STEP.STEP_ONE);
                            onSuccessCreateOrder();
                            return [2 /*return*/];
                    }
                });
            });
        },
    });
    var mutationCreatePayment = useCreatePaymentOrderMutation({
        onSuccess: function () {
            clearAllOrderCreate();
        },
    });
    function handleResultAlertCleanCart(result) {
        if (result === ENUM_RESULT_ALERT_BUTTON.ACCEPT) {
            clearAllProductToCart();
            toast.success({ description: 'Se vació el carrito correctamente.' });
            disclosureCleanCart.onClose();
        }
        else {
            disclosureCleanCart.onClose();
        }
    }
    function validateCreateOrder() {
        if (slugOrderType === ENUM_SLUG_ORDER_TYPE.PICKUP) {
            return !havePaymentsOrder
                ? !(itemsQuantity > 0 && (storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.is_open))
                : !(itemsQuantity > 0 && (storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.is_open) && isPossiblePayment);
        }
        if (slugOrderType === ENUM_SLUG_ORDER_TYPE.DELIVERY) {
            return !havePaymentsOrder
                ? !(itemsQuantity > 0 &&
                    (storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.is_open) &&
                    clientAddressOrder != null)
                : !(itemsQuantity > 0 &&
                    (storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.is_open) &&
                    isPossiblePayment &&
                    clientAddressOrder != null);
        }
    }
    return (_jsxs(Box, __assign({ sx: {
            position: 'relative',
            width: '100%',
            height: "100vh",
        } }, { children: [_jsxs(Box, __assign({ px: 4 }, { children: [!isDesktop && (_jsx(Box, __assign({ sx: { width: '100%' } }, { children: _jsx(Box, { sx: {
                                width: 150,
                                height: 5,
                                bgcolor: '#e6e6e6',
                                borderRadius: 2.5,
                                position: 'fixed',
                                top: 15,
                                left: '50%',
                                transform: 'translateX(-50%)',
                            } }) }))), _jsx(Stack, __assign({ direction: "row", alignItems: "center", justifyContent: "space-between", sx: { py: 4, pb: 0 } }, { children: _jsx(ContainerOrderTypes, {}) })), _jsx(Box, __assign({ sx: { width: '100%', my: 4 } }, { children: _jsxs(Stepper, __assign({ activeStep: stepCreateOrder, nonLinear: true }, { children: [_jsx(Step, { children: _jsx(StepButton, __assign({ color: "inherit", onClick: function () {
                                            return setStepCreateOrder(ENUM_CREATE_ORDER_STEP.STEP_ONE);
                                        } }, { children: _jsx(StepLabel, { children: "Orden" }) })) }), _jsx(Step, { children: _jsx(StepButton, __assign({ color: "inherit", onClick: function () {
                                            return setStepCreateOrder(ENUM_CREATE_ORDER_STEP.STEP_TWO);
                                        }, disabled: itemsQuantity === 0 }, { children: _jsx(StepLabel, { children: "Informaci\u00F3n y pago" }) })) })] })) })), _jsx(FormControl, __assign({ sx: { maxWidth: 200 } }, { children: _jsx(SelectPicker, { label: _jsx(Typography, __assign({ variant: "button", color: "grey" }, { children: "Zona" })), data: spots === null || spots === void 0 ? void 0 : spots.map(function (s) { return (__assign(__assign({}, s), { label: s.spot.name, value: s.spot.uuid })); }), value: spotOrder === null || spotOrder === void 0 ? void 0 : spotOrder.uuid, defaultValue: spotOrder === null || spotOrder === void 0 ? void 0 : spotOrder.uuid, onChange: function (e) { return handleChangeSpot(e); }, cleanable: false, loading: isLoadingSpots }) })), _jsxs(FormControl, __assign({ fullWidth: true, sx: { mt: 2 } }, { children: [_jsx(Typography, __assign({ variant: "button", color: "grey" }, { children: "Instrucciones adicionales del pedido" })), _jsx(Input, { placeholder: "Ingrese alg\u00FAn comentario para la orden en general...", multiple: true, value: commentOrder !== null ? commentOrder : '', defaultValue: commentOrder !== null ? commentOrder : '', onChange: function (event) { return onSetCommentOrder(event); } })] }))] })), _jsx(Divider, { sx: { my: 2, borderColor: function (_a) {
                        var palette = _a.palette;
                        return palette.secondary.light;
                    } } }), stepCreateOrder === ENUM_CREATE_ORDER_STEP.STEP_ONE &&
                (productsCart === null || productsCart === void 0 ? void 0 : productsCart.length) > 0 && (_jsxs(Stack, __assign({ px: 4, direction: "row", justifyContent: "space-between", alignItems: "center", py: 2 }, { children: [_jsx(Text, __assign({ size: "lg", weight: "bold" }, { children: "Productos" })), _jsx(Button, __assign({ size: "sm", appearance: "ghost", color: "red", onClick: disclosureCleanCart.onOpen }, { children: "Limpiar carrito" }))] }))), _jsxs(Box, { children: [stepCreateOrder === ENUM_CREATE_ORDER_STEP.STEP_ONE && (_jsx(Box, __assign({ sx: {
                            height: "calc(100vh - ".concat(600, "px)"),
                            overflowY: 'auto',
                            pl: 4,
                            pr: 1,
                            pb: isDesktop ? 0 : 10,
                        } }, { children: (productsCart === null || productsCart === void 0 ? void 0 : productsCart.length) === 0 ? (_jsxs(Stack, __assign({ width: "100%", alignItems: "center", spacing: 1 }, { children: [_jsx(Box, { width: "100%", maxWidth: 250, component: "img", src: imageEmptyBox, sx: { opacity: 0.8 } }), _jsx(Typography, __assign({ variant: "button", color: "gray" }, { children: "No se tiene productos seleccionados." }))] }))) : (_jsx(_Fragment, { children: _jsxs(Stack, __assign({ width: "100%", alignItems: "center", spacing: 1 }, { children: [productsCart === null || productsCart === void 0 ? void 0 : productsCart.map(function (item) { return (_jsx(CardProductBasketComponent, { item: item }, item.auxID)); }), _jsx(AlertComponent, { title: "Vaciar carrito", description: "Estas segura/o de vaciar el carrito?", isOpen: disclosureCleanCart.isOpen, onClose: disclosureCleanCart.onClose, onResult: handleResultAlertCleanCart, backdrop: undefined })] })) })) }))), stepCreateOrder === ENUM_CREATE_ORDER_STEP.STEP_TWO && (_jsx(Box, __assign({ sx: {
                            height: "calc(100vh - ".concat(550, "px)"),
                            overflowY: 'auto',
                            px: 4,
                            pb: isDesktop ? 0 : 10,
                        } }, { children: _jsx(InformationAndPaymentContainer, { onIsPossiblePayment: setIsPossiblePayment, disclosureEmitVoucher: disclosureEmitVoucher }) })))] }), _jsxs(BoxComponent, __assign({ sx: {
                    width: '100%',
                    boxShadow: '0px -2px 5px #e6e6e6',
                    background: 'white',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    mb: 0,
                    borderEndEndRadius: 0,
                    borderEndStartRadius: 0,
                } }, { children: [_jsx(Table, __assign({ size: "small", sx: { position: 'sticky', top: 0, background: 'white' } }, { children: _jsxs(TableBody, { children: [_jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(Typography, __assign({ variant: "h1", fontWeight: 500, fontSize: 18 }, { children: "Subtotal" })) }), _jsx(TableCell, __assign({ align: "right" }, { children: _jsx(Typography, __assign({ variant: "h1", fontWeight: 500, fontSize: 18 }, { children: (_c = calculates.subtotal) === null || _c === void 0 ? void 0 : _c.formatted })) }))] }), _jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(Typography, __assign({ variant: "h1", fontWeight: 500, fontSize: 18 }, { children: "Descuentos" })) }), _jsx(TableCell, __assign({ align: "right" }, { children: _jsx(Typography, __assign({ variant: "h1", fontWeight: 500, fontSize: 18 }, { children: (_d = calculates.discount) === null || _d === void 0 ? void 0 : _d.formatted })) }))] }), _jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(Typography, __assign({ variant: "h6", fontSize: 19, fontWeight: "bold" }, { children: "Total" })) }), _jsx(TableCell, __assign({ align: "right" }, { children: _jsx(Typography, __assign({ variant: "h6", fontSize: 19, fontWeight: "bold" }, { children: (_e = calculates.total) === null || _e === void 0 ? void 0 : _e.formatted })) }))] })] }) })), ((_f = orderSpot === null || orderSpot === void 0 ? void 0 : orderSpot.cashiers) === null || _f === void 0 ? void 0 : _f.length) > 0 && (_jsxs(Stack, __assign({ direction: "row", spacing: 2, sx: { background: 'white', py: 2, px: 4 } }, { children: [_jsx(Button, __assign({ appearance: !havePaymentsOrder ? 'ghost' : 'primary', style: { width: '100%' }, onClick: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, mutationCreateOrder.mutateAsync(false)];
                                        case 1: return [2 /*return*/, _a.sent()];
                                    }
                                }); }); }, disabled: validateCreateOrder() }, { children: "Crear orden" })), !havePaymentsOrder &&
                                stepCreateOrder === ENUM_CREATE_ORDER_STEP.STEP_ONE && (_jsx(Button, __assign({ appearance: "primary", style: { width: '100%' }, disabled: !(storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.is_open) ||
                                    itemsQuantity === 0 ||
                                    (havePaymentsOrder &&
                                        sumAmountChargedPayments.number !==
                                            ((_g = calculates.total) === null || _g === void 0 ? void 0 : _g.number)), onClick: function () {
                                    return setStepCreateOrder(ENUM_CREATE_ORDER_STEP.STEP_TWO);
                                } }, { children: "Informaci\u00F3n y pagos" }))), havePaymentsOrder && (_jsx(Button, __assign({ appearance: "primary", style: { width: '100%' }, onClick: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, mutationCreateOrder.mutateAsync(true)];
                                        case 1: return [2 /*return*/, _a.sent()];
                                    }
                                }); }); }, disabled: validateCreateOrder() ||
                                    sumAmountChargedPayments.number !== ((_h = calculates.total) === null || _h === void 0 ? void 0 : _h.number), color: "red" }, { children: "Crear orden y finalizar" })))] })))] }))] })));
}
export default memo(CreateOrderContainer);
