var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Skeleton, Stack } from '@mui/material';
export function CardProductLoading() {
    return (_jsx(Box, __assign({ sx: {
            width: '100%',
            maxWidth: 380,
            height: 230,
            borderRadius: '10px',
            p: 2,
            position: 'relative',
            border: function (theme) { return "1.5px solid ".concat(theme.palette.divider); },
        } }, { children: _jsxs(Stack, __assign({ direction: "row", alignItems: "flex-start", justifyContent: "flex-start", spacing: 2 }, { children: [_jsx(Skeleton, { width: 150, height: 200, sx: { borderRadius: 2 } }), _jsxs(Stack, __assign({ flex: 1 }, { children: [_jsx(Skeleton, {}), _jsx(Stack, __assign({ direction: "row", spacing: 1.5, alignItems: "center", justifyContent: "space-between", sx: { mb: 1, flex: 1 } }, { children: _jsx(_Fragment, { children: _jsx(Stack, { children: _jsx(Skeleton, { width: 100 }) }) }) }))] }))] })) })));
}
