var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQueryParams } from '@hooks/useQueryParams';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import TableBarIcon from '@mui/icons-material/TableBar';
import { memo, useEffect } from 'react';
import { Badge, Button, ButtonGroup, ButtonToolbar, Stack } from 'rsuite';
import { useStore } from '../../context/Store';
import useFetchOrderTypesOfStore from '../../hooks/fetch/useFetchOrderTypesOfStore';
import { ENUM_SLUG_ORDER_TYPE } from '../../services/orderTypes/types/IOrderType';
function ContainerOrderTypes() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var slugsOfOrderTypesOfStore = useStore().slugsOfOrderTypesOfStore;
    var _p = useQueryParams(), getValueQuery = _p.getValueQuery, addQuery = _p.addQuery, deleteQuery = _p.deleteQuery;
    var orderType = (_a = getValueQuery('ot')) !== null && _a !== void 0 ? _a : '';
    var _q = useFetchOrderTypesOfStore(), orderTypes = _q.data, isLoadingOrderTypes = _q.isLoading;
    useEffect(function () {
        var _a;
        if (!orderType &&
            (slugsOfOrderTypesOfStore === null || slugsOfOrderTypesOfStore === void 0 ? void 0 : slugsOfOrderTypesOfStore.length) > 0 &&
            (orderTypes === null || orderTypes === void 0 ? void 0 : orderTypes.length) > 0) {
            var isMain = orderTypes.find(function (o) { return o.is_main; });
            if (isMain) {
                addQuery('ot', (_a = isMain.order_type) === null || _a === void 0 ? void 0 : _a.slug, true);
            }
            else {
                addQuery('ot', slugsOfOrderTypesOfStore[0], true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderTypes, slugsOfOrderTypesOfStore, orderTypes]);
    function handleChangeOrderType(_, value) {
        if (value === null)
            return;
        if (orderType === value)
            return;
        addQuery('ot', value, true);
        deleteQuery('pag', true);
    }
    return (_jsx(_Fragment, { children: _jsx(ButtonToolbar, { children: _jsx(ButtonGroup, __assign({ size: "md", justified: true, block: true, style: { border: '1px solid #e6e6e6', borderRadius: 8 } }, { children: isLoadingOrderTypes ? (_jsx(Button, __assign({ appearance: "subtle", loading: isLoadingOrderTypes }, { children: "Cargando..." }))) : (_jsxs(_Fragment, { children: [slugsOfOrderTypesOfStore.includes(ENUM_SLUG_ORDER_TYPE.ON_TABLE) && (_jsx(Button, __assign({ appearance: orderType === ENUM_SLUG_ORDER_TYPE.ON_TABLE
                                ? 'primary'
                                : 'default', onClick: function () {
                                return handleChangeOrderType('', ENUM_SLUG_ORDER_TYPE.ON_TABLE);
                            } }, { children: _jsx(Badge, __assign({ color: "green", content: (_e = (_d = (_c = (_b = orderTypes === null || orderTypes === void 0 ? void 0 : orderTypes.find(function (ot) { var _a; return ((_a = ot.order_type) === null || _a === void 0 ? void 0 : _a.slug) === ENUM_SLUG_ORDER_TYPE.ON_TABLE; })) === null || _b === void 0 ? void 0 : _b.order_type) === null || _c === void 0 ? void 0 : _c._count) === null || _d === void 0 ? void 0 : _d.orders) !== null && _e !== void 0 ? _e : 0 }, { children: _jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(TableBarIcon, { fontSize: "small" }), "On table"] })) })) }))), slugsOfOrderTypesOfStore.includes(ENUM_SLUG_ORDER_TYPE.PICKUP) && (_jsx(Button, __assign({ appearance: orderType === ENUM_SLUG_ORDER_TYPE.PICKUP
                                ? 'primary'
                                : 'default', onClick: function () {
                                return handleChangeOrderType('', ENUM_SLUG_ORDER_TYPE.PICKUP);
                            } }, { children: _jsx(Badge, __assign({ color: "green", content: (_j = (_h = (_g = (_f = orderTypes === null || orderTypes === void 0 ? void 0 : orderTypes.find(function (ot) { var _a; return ((_a = ot.order_type) === null || _a === void 0 ? void 0 : _a.slug) === ENUM_SLUG_ORDER_TYPE.PICKUP; })) === null || _f === void 0 ? void 0 : _f.order_type) === null || _g === void 0 ? void 0 : _g._count) === null || _h === void 0 ? void 0 : _h.orders) !== null && _j !== void 0 ? _j : 0 }, { children: _jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(DirectionsWalkIcon, { fontSize: "small" }), "Recojo"] })) })) }))), slugsOfOrderTypesOfStore.includes(ENUM_SLUG_ORDER_TYPE.DELIVERY) && (_jsx(Button, __assign({ appearance: orderType === ENUM_SLUG_ORDER_TYPE.DELIVERY
                                ? 'primary'
                                : 'default', onClick: function () {
                                return handleChangeOrderType('', ENUM_SLUG_ORDER_TYPE.DELIVERY);
                            } }, { children: _jsx(Badge, __assign({ color: "green", content: (_o = (_m = (_l = (_k = orderTypes === null || orderTypes === void 0 ? void 0 : orderTypes.find(function (ot) { var _a; return ((_a = ot.order_type) === null || _a === void 0 ? void 0 : _a.slug) === ENUM_SLUG_ORDER_TYPE.DELIVERY; })) === null || _k === void 0 ? void 0 : _k.order_type) === null || _l === void 0 ? void 0 : _l._count) === null || _m === void 0 ? void 0 : _m.orders) !== null && _o !== void 0 ? _o : 0 }, { children: _jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(DeliveryDiningIcon, { fontSize: "small" }), "Envio"] })) })) })))] })) })) }) }));
}
export default memo(ContainerOrderTypes);
