var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useMemo, useState, } from 'react';
import usePersistedState from '../hooks/usePersistedState';
import { formattedCurrency } from '../utils/formattedCurrency';
import { CREATE_ORDER_CATALOG_SELECTED, CREATE_ORDER_CURRENCY_SELECTED, CREATE_ORDER_SPOT_SELECTED, } from '../utils/storageConstants';
import { sendBigInt } from '@utils/manageBigInt';
var OrderContext = createContext({});
var OrderProvider = function (_a) {
    var children = _a.children;
    var _b = usePersistedState(CREATE_ORDER_CURRENCY_SELECTED, null), currency = _b[0], setCurrency = _b[1];
    var _c = usePersistedState(CREATE_ORDER_SPOT_SELECTED, null), spot = _c[0], setSpot = _c[1];
    var _d = usePersistedState(CREATE_ORDER_CATALOG_SELECTED, null), catalog = _d[0], setCatalog = _d[1];
    var _e = useState(false), havePaymentsOrder = _e[0], setHavePaymentsOrder = _e[1];
    var _f = useState([]), orderPayments = _f[0], setOrderPayments = _f[1];
    var _g = useState(null), commentOrder = _g[0], setCommentOrder = _g[1];
    var _h = useState(null), clientOrder = _h[0], setClientOrder = _h[1];
    var _j = useState(null), clientAddressOrder = _j[0], setClientAddressOrder = _j[1];
    var onSetClientOrder = useCallback(function (data) {
        setClientOrder(data);
    }, []);
    var onSetClientAddressOrder = useCallback(function (data) {
        setClientAddressOrder(data);
    }, []);
    var onCurrencySelected = useCallback(function (data) {
        setCurrency(data);
    }, []);
    var onSpotSelected = useCallback(function (data) {
        setSpot(data);
    }, []);
    var onCatalogSelected = useCallback(function (data) {
        setCatalog(data);
    }, []);
    var onClearOrderContext = useCallback(function () {
        setCurrency(null);
        setSpot(null);
        setCatalog(null);
        setCommentOrder(null);
    }, []);
    var onToggleHavePaymentsOrder = useCallback(function () {
        setHavePaymentsOrder(function (state) { return !state; });
    }, []);
    var onUpdateOrderPayments = useCallback(function (data) {
        setOrderPayments(data);
    }, []);
    var onSetCommentOrder = useCallback(function (comment) {
        if (comment.trim().length === 0) {
            setCommentOrder(null);
        }
        else {
            setCommentOrder(comment);
        }
    }, [commentOrder]);
    var sumAmountChargedPayments = useMemo(function () {
        var amounts = orderPayments.reduce(function (acc, curr) {
            return acc + sendBigInt(curr.chargedAmount);
        }, 0);
        return {
            number: amounts,
            format: formattedCurrency({ amount: amounts, currency: currency }),
        };
    }, [orderPayments, currency]);
    var onClearPaymentData = useCallback(function () {
        setHavePaymentsOrder(false);
        setOrderPayments([]);
    }, []);
    return (_jsx(OrderContext.Provider, __assign({ value: {
            currency: currency,
            spot: spot,
            catalog: catalog,
            orderPayments: orderPayments,
            havePaymentsOrder: havePaymentsOrder,
            sumAmountChargedPayments: sumAmountChargedPayments,
            onClearOrderContext: onClearOrderContext,
            onCurrencySelected: onCurrencySelected,
            onSpotSelected: onSpotSelected,
            onCatalogSelected: onCatalogSelected,
            onToggleHavePaymentsOrder: onToggleHavePaymentsOrder,
            onUpdateOrderPayments: onUpdateOrderPayments,
            onClearPaymentData: onClearPaymentData,
            commentOrder: commentOrder,
            clientOrder: clientOrder,
            clientAddressOrder: clientAddressOrder,
            onSetCommentOrder: onSetCommentOrder,
            onSetClientOrder: onSetClientOrder,
            onSetClientAddressOrder: onSetClientAddressOrder,
        } }, { children: children })));
};
function useOrder() {
    var context = useContext(OrderContext);
    if (!context)
        throw new Error('useOrder must be used within a OrderProvider');
    return context;
}
export { OrderProvider, useOrder };
