var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Can from '@components/Can';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { MenuOpenRounded } from '@mui/icons-material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Box, IconButton, Menu, MenuItem, Stack, Tabs, Typography, } from '@mui/material';
import { optionSideMenu } from '@pages/Layout/LeftSideMenu';
import { useWindowWidth } from '@react-hook/window-size';
import { rem } from 'polished';
import { memo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FiChevronDown } from 'react-icons/fi';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Button, Divider } from 'rsuite';
import { useAuth } from '../../context/Auth';
import { useOrder } from '../../context/Order';
import { useStore } from '../../context/Store';
import CircleAvatarComponent from '../CircleAvatarComponent';
import ButtonComponent from '../Form/ButtonComponent';
import { SelectPickerStore } from './select-picker-store';
function Header() {
    var _a, _b;
    var pathname = useLocation().pathname;
    var navigate = useNavigate();
    var onClearOrderContext = useOrder().onClearOrderContext;
    var downSm = useMediaQuery().downSm;
    var windowWidth = useWindowWidth();
    var _c = useState(null), anchorEl = _c[0], setAnchorEl = _c[1];
    var open = Boolean(anchorEl);
    var _d = useAuth(), user = _d.user, logout = _d.logout, roles = _d.roles;
    var _e = useStore(), storeSelected = _e.storeSelected, cleanContextStore = _e.cleanContextStore, disclosureSideMenu = _e.disclosureSideMenu;
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    function handleSignOut() {
        logout();
        cleanContextStore();
        onClearOrderContext();
    }
    return (_jsxs(Box, __assign({ sx: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
        } }, { children: [_jsxs(Stack, __assign({ direction: "row", alignItems: "center", justifyContent: "space-between", style: { height: rem(60), padding: "0 ".concat(rem(10)) } }, { children: [_jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1 }, { children: [_jsx(IconButton, __assign({ color: "primary", onClick: isMobile ? disclosureSideMenu.onOpen : undefined }, { children: isMobile ? _jsx(MenuOpenRounded, {}) : _jsx(LocalOfferIcon, {}) })), _jsx(Divider, { vertical: true }), !isMobile && _jsx(SelectPickerStore, {})] })), _jsxs(Stack, __assign({ direction: "row", spacing: 2, alignItems: "center" }, { children: [_jsx(ButtonComponent, __assign({ id: "basic-button-test", "aria-controls": open ? 'basic-menu-test' : undefined, "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined, onClick: handleClick, variant: "text", color: "secondary" }, { children: _jsxs(Stack, __assign({ direction: "row", spacing: 1.1, alignItems: "center" }, { children: [_jsx(CircleAvatarComponent, { name: user.first_name, url: user.avatar_url, size: 35 }), _jsx(FiChevronDown, {})] })) })), _jsxs(Menu, __assign({ id: "basic-menu-test", anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                                    'aria-labelledby': 'basic-button-test',
                                } }, { children: [_jsx(MenuItem, { children: _jsxs(Stack, __assign({ alignItems: "flex-start", sx: {
                                                display: downSm ? 'none' : undefined,
                                                visibility: downSm ? 'hidden' : 'visible',
                                            }, spacing: 0.1 }, { children: [_jsxs(Typography, __assign({ component: "p", textTransform: "none", variant: "button" }, { children: [user.first_name, " ", user.last_name] })), storeSelected && (_jsx(Typography, __assign({ variant: "overline", color: "gray", fontSize: 11, lineHeight: 1 }, { children: (_b = (_a = roles[storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.value]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '' })))] })) }), _jsx(Divider, { style: { marginBlock: 2 } }), _jsx(MenuItem, __assign({ onClick: function () { return navigate('/profile'); } }, { children: "Perfil" })), _jsx(MenuItem, __assign({ onClick: handleSignOut }, { children: "Cerrar sesi\u00F3n" }))] }))] }))] })), _jsx(Divider, { style: { margin: 0 } }), _jsx(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1, style: {
                    padding: "0 ".concat(rem(10)),
                    width: '100%',
                    maxWidth: windowWidth,
                    height: rem(35),
                } }, { children: _jsx(Tabs, __assign({ variant: "scrollable", scrollButtons: true, allowScrollButtonsMobile: true, centered: true, sx: { alignItems: 'center', height: rem(35) } }, { children: optionSideMenu.map(function (_a) {
                        var path = _a.path, title = _a.title, icon = _a.icon, rolesPermission = _a.rolesPermission, permissions = _a.permissions;
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        var isActivePath = pathname === path;
                        return (_jsxs(Can, __assign({ permissions: permissions, roles: rolesPermission }, { children: [_jsx(NavLink, __assign({ to: path, style: { textDecoration: 'none' } }, { children: _jsx(Button, __assign({ startIcon: icon, appearance: isActivePath ? 'link' : 'subtle', style: {
                                            fontWeight: 'bold',
                                        }, size: "xs" }, { children: title })) }), path), _jsx(Divider, { vertical: true, style: { margin: "auto ".concat(rem(4)), height: 15 } })] }), title));
                    }) })) }))] })));
}
export default memo(Header);
