var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, CircularProgress, } from '@mui/material';
import InputComponents from '../InputComponents';
import isEmpty from 'is-empty';
function SelectAutocompleteComponent(_a) {
    var label = _a.label, _b = _a.size, size = _b === void 0 ? 'small' : _b, options = _a.options, width = _a.width, value = _a.value, _c = _a.loading, loading = _c === void 0 ? false : _c, isOptionEqualToValue = _a.isOptionEqualToValue, getOptionLabel = _a.getOptionLabel, onChange = _a.onChange, _d = _a.required, required = _d === void 0 ? false : _d, _e = _a.multiple, multiple = _e === void 0 ? false : _e, error = _a.error, helperText = _a.helperText, placeholder = _a.placeholder, disabled = _a.disabled;
    return (_jsx(Autocomplete, { disablePortal: true, id: "combo-box-categories", value: value, options: options, sx: { width: '100%', maxWidth: width }, isOptionEqualToValue: isOptionEqualToValue, getOptionLabel: getOptionLabel, multiple: multiple, disabled: disabled, filterSelectedOptions: multiple, renderInput: function (params) { return (_jsx(InputComponents, __assign({}, params, { size: size, label: label, required: required, error: !isEmpty(error), helperText: !!helperText && isEmpty(error)
                ? helperText
                : !isEmpty(error)
                    ? error.message
                    : '', placeholder: placeholder, InputProps: __assign(__assign({}, params.InputProps), { endAdornment: (_jsxs(_Fragment, { children: [loading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))); }, loading: loading, onChange: onChange }));
}
export default SelectAutocompleteComponent;
