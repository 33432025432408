import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Navigate } from 'react-router-dom';
import useCan from '../../hooks/useCan';
var Can = function (_a) {
    var children = _a.children, permissions = _a.permissions, roles = _a.roles, _b = _a.mode, mode = _b === void 0 ? 'component' : _b;
    var userCanSeeComponent = useCan({ permissions: permissions, roles: roles });
    if (!userCanSeeComponent && mode === 'component') {
        return null;
    }
    if (!userCanSeeComponent && mode === 'route') {
        return _jsx(Navigate, { to: "/not-allowed" });
    }
    return _jsx(_Fragment, { children: children });
};
export default React.memo(Can);
