var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { Message } from 'rsuite';
import { useStore } from '../../../context/Store';
function AlertTileOfCloseStoreSelected() {
    var storeSelected = useStore().storeSelected;
    return (_jsx(_Fragment, { children: storeSelected && (storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.is_open) === false && (_jsx(_Fragment, { children: _jsxs(Message, __assign({ showIcon: true, type: "warning" }, { children: [_jsx("strong", { children: "Advertencia!" }), " La tienda se encuentra ", _jsx("b", { children: "cerrada" }), ' ', "actualmente ", _jsx("br", {}), "Por favor contacte a un ", _jsx("b", { children: "administrador o encargado" }), " para que pueda aperturar la tienda"] })) })) }));
}
export default memo(AlertTileOfCloseStoreSelected);
