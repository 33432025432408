var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useState, } from 'react';
var LoadingRequestContext = createContext({});
var LoadingRequestProvider = function (_a) {
    var children = _a.children;
    var _b = useState({
        isLoading: false,
        title: '',
    }), isLoading = _b[0], setIsLoading = _b[1];
    var showLoading = useCallback(function (title) {
        if (title === void 0) { title = 'Cargando...'; }
        setIsLoading({ isLoading: true, title: title });
    }, []);
    var hiddenLoading = useCallback(function () {
        setIsLoading({ isLoading: false, title: 'Cargando...' });
    }, []);
    return (_jsx(LoadingRequestContext.Provider, __assign({ value: { isLoadingRequest: isLoading, showLoading: showLoading, hiddenLoading: hiddenLoading } }, { children: children })));
};
function useLoadingRequest() {
    var context = useContext(LoadingRequestContext);
    if (!context) {
        throw new Error('useLoadingRequest must be used within an LoadingRequestProvider');
    }
    return context;
}
export { LoadingRequestProvider, useLoadingRequest };
