var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DropZone from '@components/DropZone';
import { Chip, Typography } from '@mui/material';
import { formattedCurrency } from '@utils/formattedCurrency';
import { getBigInt } from '@utils/manageBigInt';
import { memo, useEffect, useState } from 'react';
import { DatePicker, Divider, InputNumber, Panel, SelectPicker, Stack, } from 'rsuite';
function CardPayment(_a) {
    var isLoadingPaymentMethods = _a.isLoadingPaymentMethods, paymentMethods = _a.paymentMethods, data = _a.data, onChange = _a.onChange, orderCurrency = _a.orderCurrency;
    var files = useState(null)[0];
    useEffect(function () {
        if ((files === null || files === void 0 ? void 0 : files.length) > 0) {
            onChange('image', files[0]);
        }
    }, [files, onChange]);
    return (_jsx(Panel, __assign({ bordered: true }, { children: _jsxs(Stack, __assign({ spacing: 20, direction: "column", alignItems: "flex-start" }, { children: [_jsx(Stack.Item, __assign({ style: { width: '100%' } }, { children: _jsx(SelectPicker, { style: { width: '100%' }, loading: isLoadingPaymentMethods, label: _jsx(Typography, __assign({ variant: "button" }, { children: "M\u00E9todo de pago" })), data: paymentMethods === null || paymentMethods === void 0 ? void 0 : paymentMethods.map(function (pm) { return (__assign(__assign({}, pm), { label: pm.name, value: pm.id })); }), value: data.paymentMethod.id, defaultValue: data.paymentMethod.id, cleanable: false, onSelect: function (_, item) { return onChange('paymentMethod', item); }, renderValue: function (_, pm) {
                            return (_jsx(Stack, { children: _jsxs(Stack, __assign({ direction: "row", spacing: 5, alignItems: "center" }, { children: [_jsx("img", { src: pm.image, alt: pm.name, style: { width: 25, height: 25, borderRadius: 5 } }), _jsx(Typography, __assign({ variant: "button" }, { children: pm.name }))] })) }));
                        } }) })), _jsx(Stack.Item, __assign({ style: { width: '100%' }, flex: 1 }, { children: _jsxs(Stack, __assign({ alignItems: "center", justifyContent: "space-between", spacing: 20, style: { width: '100%' } }, { children: [_jsx(Stack.Item, __assign({ style: { width: '100%' }, flex: 1 }, { children: _jsxs(Stack, __assign({ style: { width: '100%' }, direction: "column", alignItems: "flex-start" }, { children: [_jsx(Typography, __assign({ variant: "button" }, { children: "Monto a cobrar" })), _jsx(InputNumber, { style: { width: '100%' }, scrollable: false, value: data.chargedAmount, defaultValue: data.chargedAmount, placeholder: "0.00", onChange: function (e) { return onChange('chargedAmount', e); }, prefix: "S/" })] })) })), _jsx(Stack.Item, __assign({ style: { width: '100%' }, flex: 1 }, { children: _jsxs(Stack, __assign({ direction: "column", alignItems: "flex-start" }, { children: [_jsx(Typography, __assign({ variant: "button" }, { children: "Efectivo recibido" })), _jsx(InputNumber, { style: { width: '100%' }, scrollable: false, value: data.enteredAmount, defaultValue: data.enteredAmount, onChange: function (e) { return onChange('enteredAmount', e); }, placeholder: "0.00", prefix: "S/", disabled: data.paymentMethod.type !== 'cash' })] })) }))] })) })), _jsxs(Stack, __assign({ spacing: 1 }, { children: [_jsx(Typography, __assign({ variant: "button" }, { children: "Vuelto" })), _jsx(Divider, { vertical: true }), _jsx(Chip, { sx: {
                                width: 'fit-content',
                                color: function (_a) {
                                    var palette = _a.palette;
                                    return palette.success.main;
                                },
                                backgroundColor: 'success.light',
                                fontWeight: 600,
                                fontSize: 14,
                            }, label: formattedCurrency({
                                currency: orderCurrency,
                                amount: getBigInt(data.enteredAmountWithoutFormat -
                                    data.chargedAmountWithoutFormat),
                            }) })] })), _jsx(Stack.Item, __assign({ style: { width: '100%' }, flex: 1 }, { children: _jsx(DatePicker, { format: "dd/MM/yyyy hh:mm aa", label: _jsx(Typography, __assign({ variant: "button" }, { children: "Fecha de pago" })), value: new Date(data.paymentDate), defaultValue: new Date(data.paymentDate), onChange: function (date) { return onChange('paymentDate', date); }, cleanable: false, style: { width: '100%' } }) })), _jsx(Stack.Item, __assign({ style: { width: '100%' }, flex: 1 }, { children: _jsx(DropZone, { heightBox: 100, dropzoneOptions: { accept: { 'image/*': [] }, maxFiles: 1 }, files: data.image === undefined ? [] : [data.image], onDragAcceptFiles: function (files) { return onChange('image', files[0]); }, placeholder: "Suba alg\u00FAn comprobante de pago aqui...", helperText: "Solo se acepta una imagen, reemplaze arrastrando otra." }) }))] })) })));
}
export default memo(CardPayment);
