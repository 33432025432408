var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { LeftSideMenu } from '@pages/Layout/LeftSideMenu';
import { memo } from 'react';
import { useQueries, useQuery } from 'react-query';
import { Outlet, useMatch } from 'react-router-dom';
import { Divider } from 'rsuite';
import Header from '../../components/Header';
import { useAuth } from '../../context/Auth';
import { useStore } from '../../context/Store';
import api from '../../services/api';
function Main() {
    var _this = this;
    var _a = useAuth(), user = _a.user, setDataPermissions = _a.setDataPermissions, setDataRoles = _a.setDataRoles, setUserData = _a.setUserData;
    var _b = useStore(), setStoresData = _b.setStoresData, setDistributionChannelsOfStoreData = _b.setDistributionChannelsOfStoreData, storeSelected = _b.storeSelected, handleStoreSelect = _b.handleStoreSelect, onSetStoreConfiguration = _b.onSetStoreConfiguration, setStoreSelectedData = _b.setStoreSelectedData;
    useQuery('me-stores', function () { return __awaiter(_this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get('/v1/me/stores')];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        staleTime: 100 * 60 * 30,
        enabled: !!user && !!user.email,
        onSuccess: function (data) {
            var permissionsForBranch = {};
            var rolesForBranch = {};
            var storeConfigurations = {};
            var format = data.map(function (store) {
                var _a;
                return (__assign(__assign({}, store.store), { member_id: store.uuid, value: store.store.uuid, label: "".concat((_a = store.store) === null || _a === void 0 ? void 0 : _a.name, " ").concat(store.store.address ? "de ".concat(store.store.address) : '') }));
            });
            data.forEach(function (branch) {
                var _a, _b, _c;
                Object.assign(permissionsForBranch, (_a = {},
                    _a[branch.store.uuid] = branch.store_users_permissions,
                    _a));
                Object.assign(rolesForBranch, (_b = {},
                    _b[branch.store.uuid] = branch.role,
                    _b));
                Object.assign(storeConfigurations, (_c = {},
                    _c[branch.store.uuid] = branch.store.configurations,
                    _c));
            });
            onSetStoreConfiguration(storeConfigurations);
            setDataPermissions(permissionsForBranch);
            setDataRoles(rolesForBranch);
            setStoresData(format);
            if (!storeSelected) {
                setStoreSelectedData(format.length > 0 ? format[0] : null);
            }
            else if (storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.value) {
                var findStoreInResult = format.find(function (s) { return s.value === (storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.value); });
                handleStoreSelect(findStoreInResult !== null && findStoreInResult !== void 0 ? findStoreInResult : null, { force: true });
            }
        },
    });
    function getDistributionChannelsOfStore(uuid) {
        return __awaiter(this, void 0, void 0, function () {
            var response, data, distributionChannels;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("/v1/stores/".concat(uuid, "/distribution-channels"))];
                    case 1:
                        response = _a.sent();
                        data = response.data;
                        distributionChannels = data.map(function (d) { return (__assign(__assign({}, d), { value: d.uuid, label: d.distribution_channel.name })); });
                        setDistributionChannelsOfStoreData(response.data);
                        return [2 /*return*/, distributionChannels];
                }
            });
        });
    }
    useQueries([
        {
            queryKey: 'me',
            queryFn: function () { return __awaiter(_this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.get("/v1/me")];
                        case 1:
                            response = _a.sent();
                            return [2 /*return*/, response.data];
                    }
                });
            }); },
            onSuccess: function (data) {
                setUserData(data);
            },
            staleTime: 1000 * 60 * 30,
        },
        {
            queryKey: [storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid, 'distribution-channels'],
            queryFn: function () {
                if (storeSelected)
                    return getDistributionChannelsOfStore(storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid);
            },
            staleTime: 1000 * 60 * 30,
        },
    ]);
    return (_jsx(Box, { children: _jsxs(Box, __assign({ sx: { display: 'flex' } }, { children: [_jsx(LeftSideMenu, {}), _jsxs(Box, __assign({ flexGrow: 1 }, { children: [!useMatch({ path: "/orders/:action" }) && (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsx(Divider, { style: { marginBlock: 0 } })] })), _jsx(Box, __assign({ flexGrow: 1 }, { children: _jsx(Outlet, {}) }))] }))] })) }));
}
export default memo(Main);
