var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useStore } from '@context/Store';
import { Delete, SearchOutlined } from '@mui/icons-material';
import { Box, Collapse, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, TextField, } from '@mui/material';
import isEmpty from 'is-empty';
import { memo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import useDebounce from '../../hooks/useDebounce';
import api from '../../services/api';
function SearchClientsInput(_a) {
    var _this = this;
    var _b, _c, _d;
    var value = _a.value, onClearSelected = _a.onClearSelected, onClick = _a.onClick, _e = _a.cannotDelete, cannotDelete = _e === void 0 ? false : _e, _f = _a.hiddenClientsUuids, hiddenClientsUuids = _f === void 0 ? [] : _f;
    var storeSelected = useStore().storeSelected;
    var refInputSearch = useRef(null);
    var _g = useState(''), userTerm = _g[0], setUserTerm = _g[1];
    var debounce = useDebounce(userTerm);
    var _h = useQuery(['search-members', debounce], function () { return __awaiter(_this, void 0, void 0, function () {
        var response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, api.get("/v1/organizations/".concat((_a = storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.brand) === null || _a === void 0 ? void 0 : _a.organization_uuid, "/clients?term=").concat(debounce))];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data.clients];
            }
        });
    }); }, { enabled: !!debounce && !!(storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid) }), isIdle = _h.isIdle, clients = _h.data, isLoadingClients = _h.isLoading;
    return (_jsxs(Box, __assign({ sx: { position: 'relative' } }, { children: [!isEmpty(value) && (_jsxs(Stack, __assign({ direction: "row", alignItems: "center", justifyContent: "center" }, { children: [_jsx(ListItem, __assign({ disablePadding: true }, { children: _jsx(ListItemText, { primary: "".concat(value.first_name, " ").concat(value.last_name), secondary: value.email }) })), !cannotDelete && (_jsx(IconButton, __assign({ onClick: onClearSelected ? function () { return onClearSelected(); } : function () { } }, { children: _jsx(Delete, { color: "error" }) })))] }))), !value && (_jsxs(_Fragment, { children: [_jsx(TextField, { ref: refInputSearch, sx: { mb: 2, position: 'relative' }, placeholder: "Buscar clientes", InputProps: {
                            startAdornment: _jsx(SearchOutlined, { color: "disabled" }),
                        }, fullWidth: true, value: userTerm, onChange: function (e) {
                            setUserTerm(e.target.value);
                        }, helperText: "Busca por nombres, apellidos, correo electr\u00F3nico o n\u00FAmero de documento.", type: "search", size: "small", autoComplete: "off" }), _jsx(Collapse, __assign({ in: !!debounce, sx: {
                            position: 'absolute',
                            top: ((_b = refInputSearch.current) === null || _b === void 0 ? void 0 : _b.offsetHeight) - 23,
                            zIndex: 2,
                            width: '100%',
                            maxWidth: (_c = refInputSearch.current) === null || _c === void 0 ? void 0 : _c.offsetWidth,
                        } }, { children: _jsx(List, __assign({ dense: true, sx: {
                                width: '100%',
                                maxWidth: (_d = refInputSearch.current) === null || _d === void 0 ? void 0 : _d.offsetWidth,
                                overflowY: 'auto',
                                maxHeight: 250,
                                border: '1px solid #e0e0e0',
                                borderRadius: 1,
                                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                                background: 'white',
                            } }, { children: isLoadingClients ? (_jsx(ListItem, { children: _jsx(ListItemText, { primary: "Buscando clientes..." }) })) : (_jsx(_Fragment, { children: debounce.length > 0 && !!clients && (_jsx(_Fragment, { children: (clients === null || clients === void 0 ? void 0 : clients.length) === 0 ? (_jsx(ListItem, { children: _jsx(ListItemText, { primary: "No se encontraron clientes..." }) })) : (_jsx(_Fragment, { children: !isIdle &&
                                            clients.length > 0 &&
                                            (clients === null || clients === void 0 ? void 0 : clients.filter(function (m) { return !hiddenClientsUuids.includes(m.id); }).map(function (client) { return (_jsx(ListItem, __assign({ disablePadding: true }, { children: _jsx(ListItemButton, __assign({ onClick: function () {
                                                        onClick(client);
                                                        setUserTerm('');
                                                    } }, { children: _jsx(ListItemText, { primary: "".concat(client.first_name, " ").concat(client.last_name), secondary: client.email }) })) }), client.id)); })) })) })) })) })) }))] }))] })));
}
export default memo(SearchClientsInput);
