export var EnumTypeItem;
(function (EnumTypeItem) {
    EnumTypeItem["PRODUCT"] = "PRODUCT";
    EnumTypeItem["ITEM"] = "ITEM";
})(EnumTypeItem || (EnumTypeItem = {}));
export var EnumInventoryPolicyItem;
(function (EnumInventoryPolicyItem) {
    EnumInventoryPolicyItem["CONTINUE"] = "CONTINUE";
    EnumInventoryPolicyItem["DENY"] = "DENY";
})(EnumInventoryPolicyItem || (EnumInventoryPolicyItem = {}));
export var EnumInventoryPolicyItemTranslate;
(function (EnumInventoryPolicyItemTranslate) {
    EnumInventoryPolicyItemTranslate["CONTINUE"] = "CONTINUO";
    EnumInventoryPolicyItemTranslate["DENY"] = "DENEGADO";
})(EnumInventoryPolicyItemTranslate || (EnumInventoryPolicyItemTranslate = {}));
