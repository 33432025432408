import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { AuthProvider } from './Auth';
import { CartProvider } from './Cart';
import { LoadingRequestProvider } from './LoadingRequest';
import { OrderProvider } from './Order';
import { StoreProvider } from './Store';
var AppProvider = function (_a) {
    var children = _a.children;
    return (_jsx(AuthProvider, { children: _jsx(StoreProvider, { children: _jsx(LoadingRequestProvider, { children: _jsx(OrderProvider, { children: _jsx(CartProvider, { children: children }) }) }) }) }));
};
export default AppProvider;
