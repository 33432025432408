var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CardPayment from '@components/CardPayment';
import { Stack, Typography } from '@mui/material';
import { formattedCurrency } from '@utils/formattedCurrency';
import { getBigInt, sendBigInt } from '@utils/manageBigInt';
import { memo, useCallback } from 'react';
function PaymentContainer(_a) {
    var isLoadingPaymentMethods = _a.isLoadingPaymentMethods, paymentMethods = _a.paymentMethods, totals = _a.totals, orderCurrency = _a.orderCurrency, payments = _a.payments, onGetDataPayments = _a.onGetDataPayments, sumAmountChargedPayments = _a.sumAmountChargedPayments, onIsPossiblePayment = _a.onIsPossiblePayment;
    var onChangeCardPayment = useCallback(function (key, value, index) {
        var newArray = new (Array.bind.apply(Array, __spreadArray([void 0], payments, false)))();
        switch (key) {
            case 'chargedAmount':
                newArray[index].chargedAmount = value;
                newArray[index].chargedAmountWithoutFormat = sendBigInt(value);
                break;
            case 'enteredAmount':
                newArray[index].enteredAmount = value;
                newArray[index].enteredAmountWithoutFormat = sendBigInt(value);
                break;
            case 'tipAmount':
                newArray[index].tipAmount = value;
                newArray[index].tipAmountWithoutFormat = sendBigInt(value);
                break;
            default:
                newArray[index][key] = value;
                break;
        }
        if (key === 'chargedAmount') {
            newArray[index].enteredAmount = value;
            newArray[index].enteredAmountWithoutFormat = sendBigInt(value);
        }
        onGetDataPayments(newArray);
        if (onIsPossiblePayment) {
            if (key === 'chargedAmount') {
                var sumArrayAllChargedAmount = newArray.reduce(function (acc, payment) { return acc + payment.chargedAmountWithoutFormat; }, 0);
                onIsPossiblePayment(!(sumArrayAllChargedAmount > totals.totalPendingAmount));
            }
            else {
                onIsPossiblePayment(newArray.some(function (payment) {
                    return payment.enteredAmountWithoutFormat >=
                        payment.chargedAmountWithoutFormat;
                }));
            }
        }
    }, [payments]);
    return (_jsxs(Stack, __assign({ spacing: 2, alignItems: "center", sx: { mb: 2 } }, { children: [_jsx(Stack, { spacing: 4, direction: "row", alignItems: "center", sx: { userSelect: 'none', mb: 2 } }), _jsxs(Stack, __assign({ direction: "row", spacing: 5, alignItems: "center", sx: { mb: 4 } }, { children: [_jsxs(Stack, __assign({ spacing: 1, alignItems: "center" }, { children: [_jsx(Typography, __assign({ variant: "button", textAlign: "center" }, { children: "Total de la orden" })), _jsx(Typography, __assign({ variant: "h5" }, { children: formattedCurrency({
                                    amount: getBigInt(totals.totalAmount),
                                    currency: orderCurrency,
                                }) }))] })), !!(totals === null || totals === void 0 ? void 0 : totals.totalPendingAmount) &&
                        Number(totals === null || totals === void 0 ? void 0 : totals.totalPendingAmount) > 0 && (_jsxs(Stack, __assign({ spacing: 1, alignItems: "center" }, { children: [_jsx(Typography, __assign({ variant: "button", textAlign: "center" }, { children: "Total a pendiente" })), _jsx(Typography, __assign({ variant: "h5" }, { children: formattedCurrency({
                                    amount: getBigInt(totals === null || totals === void 0 ? void 0 : totals.totalPendingAmount),
                                    currency: orderCurrency,
                                }) }))] }))), _jsxs(Stack, __assign({ spacing: 1, alignItems: "center" }, { children: [_jsx(Typography, __assign({ variant: "button", textAlign: "center" }, { children: "Monto a cobrar" })), _jsx(Typography, __assign({ variant: "h5" }, { children: formattedCurrency({
                                    amount: getBigInt(sumAmountChargedPayments),
                                    currency: orderCurrency,
                                }) }))] }))] })), _jsx(Stack, __assign({ spacing: 3, sx: { mt: 4, width: '100%' } }, { children: payments.map(function (payment) { return (_jsx(CardPayment, { paymentMethods: paymentMethods.filter(function (payment) { return payment.is_active; }), isLoadingPaymentMethods: isLoadingPaymentMethods, data: payment, onChange: function (key, value) {
                        return onChangeCardPayment(key, value, payment.id);
                    }, orderCurrency: orderCurrency }, payment.id)); }) }))] })));
}
export default memo(PaymentContainer);
