var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CountrySelect from '@components/CountrySelect';
import ButtonComponent from '@components/Form/ButtonComponent';
import InputComponents from '@components/Form/InputComponents';
import SelectAutocompleteComponent from '@components/Form/SelectAutocompleteComponent';
import ModalComponent from '@components/ModalComponent';
import SearchClientsInput from '@components/SearchClientsInput';
import { useStore } from '@context/Store';
import { useCreateClientToStoreMutation } from '@hooks/mutations/useCreateClientToOrganizationMutation';
import { useUpdateClientMutation } from '@hooks/mutations/useUpdateClientMutation';
import useHandleError from '@hooks/useHandleError';
import { Stack } from '@mui/material';
import { countries } from '@utils/countries';
import { useState } from 'react';
import { Divider, Form } from 'rsuite';
var documentTypeClient = [
    { label: 'DNI', value: 'DNI' },
    { label: 'RUC', value: 'RUC' },
    { label: 'PASAPORTE', value: 'PASSPORT' },
];
export function ModalAssignClientToOrder(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var disclosure = _a.disclosure, clientOrder = _a.clientOrder, onAddClientButton = _a.onAddClientButton, onCancelButton = _a.onCancelButton, _m = _a.clearStates, clearStates = _m === void 0 ? false : _m;
    var storeSelected = useStore().storeSelected;
    var handleError = useHandleError();
    var _o = useState(null), referenceClient = _o[0], setReferenceClient = _o[1];
    var _p = useState(null), selectedClient = _p[0], setSelectedClient = _p[1];
    function handleChangeDateClient(e) {
        setSelectedClient(function (state) {
            var _a;
            return (__assign(__assign({}, state), (_a = {}, _a[e.target.name] = !e.target.value.trim() ? null : e.target.value, _a)));
        });
    }
    var createClientMutation = useCreateClientToStoreMutation({
        onSuccess: function (_a) {
            var client = _a.client;
            onAddClientButton(client);
            setSelectedClient(client);
            setReferenceClient(client);
        },
    });
    var updateClientMutation = useUpdateClientMutation({
        onSuccess: function (_a) {
            var client = _a.client;
            onAddClientButton(client);
            setSelectedClient(client);
            setReferenceClient(client);
        },
    });
    function handleAddClientToContextOrder() {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var isEqualClients, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 6, , 7]);
                        isEqualClients = JSON.stringify(referenceClient) === JSON.stringify(selectedClient);
                        if (!isEqualClients) return [3 /*break*/, 1];
                        onAddClientButton(selectedClient);
                        return [3 /*break*/, 5];
                    case 1:
                        if (!(referenceClient === null)) return [3 /*break*/, 3];
                        return [4 /*yield*/, createClientMutation.mutateAsync({
                                storeId: storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid,
                                client: __assign(__assign({}, selectedClient), { brand_id: storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.brand_uuid, organization_id: (_a = storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.brand) === null || _a === void 0 ? void 0 : _a.organization_uuid }),
                            })];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, updateClientMutation.mutateAsync({
                            client: selectedClient,
                        })];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5:
                        if (clearStates) {
                            setSelectedClient(null);
                            setReferenceClient(null);
                        }
                        disclosure.onClose();
                        return [3 /*break*/, 7];
                    case 6:
                        error_1 = _b.sent();
                        handleError(error_1);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    }
    return (_jsxs(ModalComponent, __assign({ onClose: disclosure.onClose, open: disclosure.isOpen, title: "Agregar cliente a la orden", backdrop: "static", closeButton: false, actions: [
            _jsx(ButtonComponent, __assign({ variant: "outlined", onClick: function () {
                    setSelectedClient(clientOrder);
                    setReferenceClient(clientOrder);
                    disclosure.onClose();
                    !!onCancelButton && onCancelButton();
                } }, { children: "Cancelar" })),
            _jsx(ButtonComponent, __assign({ disabled: !(((_b = selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.first_name) === null || _b === void 0 ? void 0 : _b.trim()) &&
                    ((_c = selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.last_name) === null || _c === void 0 ? void 0 : _c.trim())), onClick: handleAddClientToContextOrder }, { children: "Agregar cliente" })),
        ], overflow: true }, { children: [_jsx(SearchClientsInput, { value: referenceClient === null ? null : selectedClient, onClick: function (client) {
                    setReferenceClient(client);
                    setSelectedClient(client);
                }, onClearSelected: function () {
                    setReferenceClient(null);
                    setSelectedClient(null);
                } }), _jsx(Divider, {}), _jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(InputComponents, { size: "small", value: (_d = selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.first_name) !== null && _d !== void 0 ? _d : '', label: "Nombres completos", placeholder: "Ingrese nombres completos", name: "first_name", onChange: handleChangeDateClient }), _jsx(InputComponents, { size: "small", value: (_e = selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.last_name) !== null && _e !== void 0 ? _e : '', label: "Apellidos completos", placeholder: "Ingrese apellidos completos", name: "last_name", onChange: handleChangeDateClient }), _jsxs(Stack, __assign({ direction: "row", spacing: 2 }, { children: [_jsx(SelectAutocompleteComponent, { size: "small", label: "Tipo de documento", placeholder: "Seleccion tipo de documento", options: documentTypeClient, value: (_f = documentTypeClient === null || documentTypeClient === void 0 ? void 0 : documentTypeClient.find(function (dt) { return dt.value === (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.document_type); })) !== null && _f !== void 0 ? _f : null, isOptionEqualToValue: function (one, two) { return one.value === (two === null || two === void 0 ? void 0 : two.value); }, onChange: function (_, value, reason) {
                                    setSelectedClient(function (state) { return (__assign(__assign({}, state), { document_type: reason === 'selectOption'
                                            ? value
                                                .value
                                            : null })); });
                                } }), _jsx(InputComponents, { size: "small", value: (_g = selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.document_number) !== null && _g !== void 0 ? _g : '', label: "N\u00FAmero de documento", placeholder: "Ingrede el n\u00FAmero de documento", name: "document_number", onChange: handleChangeDateClient })] })), _jsxs(Stack, __assign({ direction: "row", spacing: 2 }, { children: [_jsxs(Form.Group, __assign({ controlId: "phone-code", style: { width: '100%' } }, { children: [_jsx(Form.ControlLabel, __assign({ style: { fontSize: 14 } }, { children: "C\u00F3digo de pa\u00EDs" })), _jsx(CountrySelect, { placeholder: "Seleccione c\u00F3digo de pa\u00EDs", value: (_j = (_h = countries.find(function (country) { return country.phone === (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.phone_code); })) === null || _h === void 0 ? void 0 : _h.phone) !== null && _j !== void 0 ? _j : null, onChange: function (value) {
                                            setSelectedClient(function (state) { return (__assign(__assign({}, state), { phone_code: value })); });
                                        } })] })), _jsx(InputComponents, { size: "small", value: (_k = selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.phone_number) !== null && _k !== void 0 ? _k : '', label: "N\u00FAmero de tel\u00E9fono", placeholder: "Ingrese n\u00FAmero de tel\u00E9fono", name: "phone_number", onChange: handleChangeDateClient })] })), _jsx(InputComponents, { size: "small", value: (_l = selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.email) !== null && _l !== void 0 ? _l : '', label: "Correo electr\u00F3nico", placeholder: "Ingrese un correo electr\u00F3nico", name: "email", onChange: handleChangeDateClient })] }))] })));
}
