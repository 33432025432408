var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { countries } from '@utils/countries';
import { InputPicker } from 'rsuite';
export default function CountrySelect(_a) {
    var value = _a.value, onChange = _a.onChange, placeholder = _a.placeholder;
    return (_jsx(InputPicker, { data: countries.map(function (c) { return (__assign(__assign({}, c), { value: c.phone })); }), style: { width: '100%' }, size: "sm", value: value, onChange: onChange, virtualized: true, renderValue: function (value) {
            var find = countries.find(function (c) { return c.phone == value; });
            return "".concat(find === null || find === void 0 ? void 0 : find.label, " ~ +").concat(find === null || find === void 0 ? void 0 : find.phone);
        }, renderMenuItem: function (label, item) { return (_jsxs(Box, __assign({ sx: { '& > img': { mr: 2, flexShrink: 0 } } }, { children: [_jsx("img", { loading: "lazy", width: "20", srcSet: "https://flagcdn.com/w40/".concat(item.code.toLowerCase(), ".png 2x"), src: "https://flagcdn.com/w20/".concat(item.code.toLowerCase(), ".png"), alt: "" }), item.label, " (", item.code, ") +", item.phone] }))); }, menuStyle: { zIndex: 9999 } }));
}
