export var ENUM_SLUG_DISTRIBUTION_CHANNEL;
(function (ENUM_SLUG_DISTRIBUTION_CHANNEL) {
    ENUM_SLUG_DISTRIBUTION_CHANNEL["POINT_OF_SALE"] = "point-of-sale";
    ENUM_SLUG_DISTRIBUTION_CHANNEL["E_COMMERCE"] = "e-commerce";
})(ENUM_SLUG_DISTRIBUTION_CHANNEL || (ENUM_SLUG_DISTRIBUTION_CHANNEL = {}));
export var ENUM_DISTRIBUTION_CHANNELS_IDENTIFICATION;
(function (ENUM_DISTRIBUTION_CHANNELS_IDENTIFICATION) {
    ENUM_DISTRIBUTION_CHANNELS_IDENTIFICATION["UUID_POINT_OF_SALE"] = "2339123e-d708-4da4-b7c5-84551c567c24";
    ENUM_DISTRIBUTION_CHANNELS_IDENTIFICATION["UUID_E_COMMERCE"] = "66fa1ff1-f13e-4e75-b05f-82e813452480";
})(ENUM_DISTRIBUTION_CHANNELS_IDENTIFICATION || (ENUM_DISTRIBUTION_CHANNELS_IDENTIFICATION = {}));
