import { useSearchParams } from 'react-router-dom';
export function useQueryParams() {
    var _a = useSearchParams(), params = _a[0], setParams = _a[1];
    function addQuery(key, value, replace) {
        if (replace === void 0) { replace = true; }
        params.set(key, value);
        setParams(params, { replace: replace });
    }
    function deleteQuery(key, replace) {
        if (replace === void 0) { replace = true; }
        var keys = Array.isArray(key) ? key : [key];
        keys.forEach(function (key) { return params.delete(key); });
        setParams(params, { replace: replace });
    }
    function getValueQuery(key) {
        return params.get(key);
    }
    return {
        addQuery: addQuery,
        deleteQuery: deleteQuery,
        hasQuery: params.has,
        getValueQuery: getValueQuery,
        queriesAsString: params.toString(),
    };
}
