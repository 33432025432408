var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
export function BoxComponent(_a) {
    var children = _a.children, sx = _a.sx, rest = __rest(_a, ["children", "sx"]);
    return (_jsx(Box, __assign({ py: 1.5, px: 2, sx: __assign({ borderRadius: 3, border: function (_a) {
                var palette = _a.palette;
                return "1px solid ".concat(palette.divider);
            }, boxShadow: function (_a) {
                var palette = _a.palette;
                return "0px 2px 10px ".concat(palette.grey[200]);
            } }, sx) }, rest, { children: children })));
}
