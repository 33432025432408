var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { Message } from 'rsuite';
import { useOrder } from '../../../context/Order';
function AlertTileVerifySpotHasOpenCashier() {
    var _a;
    var spotOrder = useOrder().spot;
    return (_jsx(_Fragment, { children: ((_a = spotOrder === null || spotOrder === void 0 ? void 0 : spotOrder.cashiers) === null || _a === void 0 ? void 0 : _a.length) === 0 && (_jsxs(Message, __assign({ showIcon: true, type: "warning" }, { children: [_jsx("strong", { children: "Advertencia!" }), " La zona ", _jsx("b", { children: spotOrder === null || spotOrder === void 0 ? void 0 : spotOrder.name }), " no tiene cajeros asignados / abiertos. Por favor verificar antes de continuar."] }))) }));
}
export default memo(AlertTileVerifySpotHasOpenCashier);
