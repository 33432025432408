var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { memo } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
function ButtonRefetching(_a) {
    var titleTooltip = _a.titleTooltip, onRefresh = _a.onRefresh, isFetching = _a.isFetching;
    return (_jsx(Tooltip, __assign({ title: titleTooltip, placement: "bottom-end", arrow: true }, { children: _jsx(LoadingButton, __assign({ onClick: onRefresh, loading: isFetching, variant: "text" }, { children: _jsx(FiRefreshCw, { size: 25 }) })) })));
}
export default memo(ButtonRefetching);
