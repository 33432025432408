// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {

  --rs-primary-50: #f2f7ff;
  --rs-primary-100: #c8dbfa;
  --rs-primary-200: #9ebff7;
  --rs-primary-300: #74a0f2;
  --rs-primary-400: #4d83f0;
  --rs-primary-500: #2563eb;
  --rs-primary-600: #1b56e0;
  --rs-primary-700: #1045cc;
  --rs-primary-800: #0733ad;
  --rs-primary-900: #002185;

  --rs-color-red: #f44336;
  --rs-color-orange: #fa8900;
  --rs-color-yellow: #ffb300;
  --rs-color-green: #4caf50;
  --rs-color-cyan: #00bcd4;
  --rs-color-blue: #2196f3;
  --rs-color-violet: #673ab7;

}

html {
  font-family: Poppins, "Helvetica Neue", "Helvetica"!important;
}

button, input, label, p {
  font-family: 'Poppins', "Helvetica Neue", "Helvetica"!important;
}

button {
  /* font-weight: 400!important; */
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;;EAEE,wBAAwB;EACxB,yBAAyB;EACzB,yBAAyB;EACzB,yBAAyB;EACzB,yBAAyB;EACzB,yBAAyB;EACzB,yBAAyB;EACzB,yBAAyB;EACzB,yBAAyB;EACzB,yBAAyB;;EAEzB,uBAAuB;EACvB,0BAA0B;EAC1B,0BAA0B;EAC1B,yBAAyB;EACzB,wBAAwB;EACxB,wBAAwB;EACxB,0BAA0B;;AAE5B;;AAEA;EACE,6DAA6D;AAC/D;;AAEA;EACE,+DAA+D;AACjE;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[":root {\n\n  --rs-primary-50: #f2f7ff;\n  --rs-primary-100: #c8dbfa;\n  --rs-primary-200: #9ebff7;\n  --rs-primary-300: #74a0f2;\n  --rs-primary-400: #4d83f0;\n  --rs-primary-500: #2563eb;\n  --rs-primary-600: #1b56e0;\n  --rs-primary-700: #1045cc;\n  --rs-primary-800: #0733ad;\n  --rs-primary-900: #002185;\n\n  --rs-color-red: #f44336;\n  --rs-color-orange: #fa8900;\n  --rs-color-yellow: #ffb300;\n  --rs-color-green: #4caf50;\n  --rs-color-cyan: #00bcd4;\n  --rs-color-blue: #2196f3;\n  --rs-color-violet: #673ab7;\n\n}\n\nhtml {\n  font-family: Poppins, \"Helvetica Neue\", \"Helvetica\"!important;\n}\n\nbutton, input, label, p {\n  font-family: 'Poppins', \"Helvetica Neue\", \"Helvetica\"!important;\n}\n\nbutton {\n  /* font-weight: 400!important; */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
