var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useSnackbar } from 'notistack';
export function useToast() {
    var _a = useSnackbar(), enqueueSnackbar = _a.enqueueSnackbar, closeSnackbar = _a.closeSnackbar;
    var defaultOptions = {
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        // preventDuplicate: true,
    };
    function success(_a) {
        var description = _a.description;
        return enqueueSnackbar(description, __assign({ variant: 'success' }, defaultOptions));
    }
    function warning(_a) {
        var description = _a.description;
        return enqueueSnackbar(description, __assign({ variant: 'warning' }, defaultOptions));
    }
    function info(_a) {
        var description = _a.description;
        return enqueueSnackbar(description, __assign({ variant: 'info' }, defaultOptions));
    }
    function error(_a) {
        var description = _a.description;
        return enqueueSnackbar(description, __assign({ variant: 'error' }, defaultOptions));
    }
    return { success: success, warning: warning, info: info, error: error, closeSnackbar: closeSnackbar };
}
