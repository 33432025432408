import { useCallback, useEffect, useRef, useState } from 'react';
export default function useDisclosure(initialValue) {
    if (initialValue === void 0) { initialValue = false; }
    var refReferenceItem = useRef(null);
    var _a = useState(initialValue), isActive = _a[0], setIsActive = _a[1];
    var toggle = useCallback(function () {
        setIsActive(function (state) { return !state; });
    }, []);
    var onClose = useCallback(function () {
        setIsActive(false);
    }, []);
    var onOpen = useCallback(function () {
        setIsActive(true);
    }, []);
    useEffect(function () {
        var checkIfClickedOutside = function (e) {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isActive &&
                refReferenceItem.current &&
                !refReferenceItem.current.contains(e.target)) {
                setIsActive(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return function () {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isActive]);
    return { isOpen: isActive, toggle: toggle, onOpen: onOpen, onClose: onClose, refReferenceItem: refReferenceItem };
}
