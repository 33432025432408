var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import 'rsuite/dist/rsuite-no-reset.min.css';
import './global.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import config from 'config';
import { SnackbarProvider } from 'notistack';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import BackDropComponent from './components/BackDropComponent';
import { useLoadingRequest } from './context/LoadingRequest';
import Routes from './routes';
import { queryClient } from './services/queryClient';
// A custom theme for this app
export var theme = createTheme({
    palette: {
        text: { primary: '#252525', secondary: '#68727d' },
        background: { default: 'white', paper: 'white' },
        primary: {
            main: '#2563EB',
            // main: '#0b77ff',
            light: '#F1F5F9',
            // light: '#ecf4fc',
        },
        info: {
            main: '#0b77ff',
            light: '#ecf4fc',
        },
        grey: {
            '50': '#fafbfc',
            '100': '#f7f7f8',
            '500': '#c1c3c7',
            '600': '#eaecf0',
        },
        secondary: {
            main: '#1F2A3D',
            light: '#F1F2F3',
        },
        error: {
            main: '#e33b32',
            light: '#feefee',
        },
        success: {
            main: '#35b973',
            light: '#e9f8f1',
        },
        warning: {
            main: '#efa230',
            light: '#fdf6ea',
        },
        action: { hover: '#f7f7f8', focus: '#4E73F7' },
        divider: '#eaecf0',
    },
    typography: {
        button: { textTransform: 'none' },
        fontFamily: 'Poppins',
    },
});
function App() {
    var isLoadingRequest = useLoadingRequest().isLoadingRequest;
    return (_jsxs(QueryClientProvider, __assign({ client: queryClient }, { children: [_jsxs(ThemeProvider, __assign({ theme: theme }, { children: [_jsx(CssBaseline, {}), (isLoadingRequest === null || isLoadingRequest === void 0 ? void 0 : isLoadingRequest.isLoading) && (_jsx(BackDropComponent, { title: isLoadingRequest.title })), _jsx(SnackbarProvider, __assign({ maxSnack: 4 }, { children: _jsx(Routes, {}) }))] })), config.env === 'development' && (_jsx(ReactQueryDevtools, { position: "bottom-left" }))] })));
}
export default App;
