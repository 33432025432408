var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { alpha, FormControl, FormHelperText, styled, TextField, Typography, } from '@mui/material';
import { memo } from 'react';
export var CustomInputField = styled(function (props) { return (_jsx(TextField, __assign({ InputProps: { disableUnderline: true } }, props))); })(function (_a) {
    var theme = _a.theme;
    return ({
        '& .MuiFilledInput-root': {
            border: '1px solid #e2e2e1',
            overflow: 'hidden',
            borderRadius: 4,
            backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            '&:hover': {
                backgroundColor: 'transparent',
            },
            '&.Mui-focused': {
                backgroundColor: 'transparent',
                boxShadow: "".concat(alpha(theme.palette.primary.main, 0.25), " 0 0 0 2px"),
                borderColor: theme.palette.primary.main,
            },
        },
    });
});
function InputComponents(_a) {
    var name = _a.name, helperText = _a.helperText, label = _a.label, error = _a.error, rest = __rest(_a, ["name", "helperText", "label", "error"]);
    return (_jsxs(FormControl, __assign({ sx: { width: '100%' } }, { children: [label && (_jsx(Typography, __assign({ variant: "caption", color: "grey" }, { children: label }))), _jsx(TextField, __assign({ autoComplete: "off", name: name, "aria-describedby": "helper-text-".concat(name), variant: "outlined", size: "small", error: error }, rest)), helperText && (_jsx(FormHelperText, __assign({ id: "helper-text-".concat(name), error: error }, { children: helperText })))] })));
}
export default memo(InputComponents);
