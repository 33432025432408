var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Backdrop, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
function BackDropComponent(_a) {
    var title = _a.title;
    return (_jsx(Backdrop, __assign({ sx: {
            color: '#fff',
            zIndex: function (theme) { var _a; return ((_a = theme.zIndex.drawer) !== null && _a !== void 0 ? _a : 0) + 999999; },
        }, open: true }, { children: _jsxs(Stack, __assign({ spacing: 2, alignItems: "center" }, { children: [_jsx(CircularProgress, { color: "inherit" }), _jsx(Typography, __assign({ variant: "overline", color: "inherit" }, { children: title }))] })) })));
}
export default BackDropComponent;
