export function formattedCurrency(_a) {
    var amount = _a.amount, currency = _a.currency;
    if (currency && currency.uuid) {
        var castAmount = Number(amount);
        var currencyFormatted = castAmount.toLocaleString(currency.country_locale_code, {
            style: 'currency',
            currency: currency.currency_country,
        });
        return currencyFormatted;
    }
    return '';
}
