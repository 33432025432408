export default function deleteKeysEmptiesOfObject(obj) {
    var newObj = {};
    Object.keys(obj).forEach(function (key) {
        if (/^\s*$/.test(obj[key])) {
            delete obj[key];
        }
        else if (obj[key] !== '' || obj[key] !== null || obj[key] !== undefined) {
            newObj[key] = obj[key];
        }
    });
    return newObj;
}
