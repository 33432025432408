import { jsx as _jsx } from "react/jsx-runtime";
import { useOrder } from '@context/Order';
import { useStore } from '@context/Store';
import { useNavigate } from 'react-router-dom';
import { SelectPicker } from 'rsuite';
export function SelectPickerStore() {
    var _a = useStore(), storeSelected = _a.storeSelected, stores = _a.stores, handleStoreSelect = _a.handleStoreSelect;
    var onClearOrderContext = useOrder().onClearOrderContext;
    var navigate = useNavigate();
    return (_jsx(SelectPicker, { label: "Tienda", data: stores !== null && stores !== void 0 ? stores : [], value: storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid, defaultValue: storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid, onSelect: function (_, item) {
            if ((storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid) !== item.uuid) {
                navigate('/');
                handleStoreSelect(item);
                onClearOrderContext();
            }
        }, cleanable: false, style: { width: '100%', maxWidth: 400 } }));
}
