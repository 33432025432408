export default function downloadBlob(data, headers, isOpen) {
    if (isOpen === void 0) { isOpen = false; }
    var url = window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] }));
    if (isOpen) {
        window.open(url, '_blank');
    }
    else {
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', headers['content-disposition'].split('=')[1]);
        document.body.appendChild(link);
        link.click();
    }
}
