var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Divider, Modal, Stack } from 'rsuite';
import ButtonComponent from '../Form/ButtonComponent';
export var ENUM_RESULT_ALERT_BUTTON;
(function (ENUM_RESULT_ALERT_BUTTON) {
    ENUM_RESULT_ALERT_BUTTON["ACCEPT"] = "ACCEPT";
    ENUM_RESULT_ALERT_BUTTON["CANCEL"] = "CANCEL";
})(ENUM_RESULT_ALERT_BUTTON || (ENUM_RESULT_ALERT_BUTTON = {}));
function AlertComponent(_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, title = _a.title, description = _a.description, _b = _a.dismissedText, dismissedText = _b === void 0 ? 'Cancelar' : _b, _c = _a.acceptedText, acceptedText = _c === void 0 ? 'Aceptar' : _c, onResult = _a.onResult, _d = _a.isLoading, isLoading = _d === void 0 ? false : _d, children = _a.children, rest = __rest(_a, ["isOpen", "onClose", "title", "description", "dismissedText", "acceptedText", "onResult", "isLoading", "children"]);
    return (_jsxs(Modal, __assign({ backdrop: "static", role: "alertdialog", open: isOpen, onClose: isLoading
            ? function () {
                //
            }
            : onClose, style: {
            zIndex: 99999,
        }, backdropStyle: { zIndex: 9997 }, overflow: true }, rest, { children: [_jsx(Modal.Header, { children: title && (_jsx(Modal.Title, __assign({ style: { fontWeight: 500 } }, { children: title }))) }), _jsxs(Modal.Body, { children: [description, !!children && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), children] }))] }), _jsx(Modal.Footer, { children: _jsxs(Stack, __assign({ spacing: 6, justifyContent: "flex-end" }, { children: [_jsx(ButtonComponent, __assign({ disabled: isLoading, variant: "text", color: "secondary", onClick: function () { return onResult(ENUM_RESULT_ALERT_BUTTON.CANCEL); } }, { children: dismissedText })), _jsx(ButtonComponent, __assign({ disabled: isLoading, color: "error", onClick: function () { return onResult(ENUM_RESULT_ALERT_BUTTON.ACCEPT); } }, { children: acceptedText }))] })) })] })));
}
export default memo(AlertComponent);
