var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AlertComponent, { ENUM_RESULT_ALERT_BUTTON, } from '@components/AlertComponent';
import ButtonRefetching from '@components/ButtonRefetching';
import CardProduct from '@components/CardProduct';
import { CardProductLoading } from '@components/CardProduct/CardProductLoading';
import DrawerComponent from '@components/DrawerComponent';
import AlertTileOfCloseStoreSelected from '@containers/AlertTilesContainer/AlertTileOfCloseStoreSelected';
import AlertTileVerifySpotHasOpenCashier from '@containers/AlertTilesContainer/AlertTileVerifySpotHasOpenCashier';
import AlertTileWithoutCatalogs from '@containers/AlertTilesContainer/AlertTileWithoutCatalogs';
import AlertTileWithoutCurrencies from '@containers/AlertTilesContainer/AlertTileWithoutCurrencies';
import AlertTileWithoutSpotOfMember from '@containers/AlertTilesContainer/AlertTileWithoutSpotOfMember';
import ContainerOrderTypes from '@containers/ContainerOrderTypes';
import CreateOrderContainer from '@containers/CreateOrderContainer';
import { useCart } from '@context/Cart';
import { useOrder } from '@context/Order';
import { useStore } from '@context/Store';
import useFetchCatalogs from '@hooks/fetch/useFetchCatalogs';
import useFetchCurrenciesToStore from '@hooks/fetch/useFetchCurrenciesToStore';
import { useFetchOrdersByOrderType } from '@hooks/fetch/useFetchOrdersByOrderType';
import useGetOrderByOrderCode from '@hooks/fetch/userGetOrder';
import { useAddItemsToOrderCreatedMutation } from '@hooks/mutations/useAddItemsToOrderCreatedMutation';
import useDebounce from '@hooks/useDebounce';
import useDisclosure from '@hooks/useDisclosure';
import useHandleError from '@hooks/useHandleError';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { useQueryParams } from '@hooks/useQueryParams';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Box, Chip, Fab, IconButton, List, ListItem, Skeleton, Stack, Tabs, Typography, } from '@mui/material';
import { useWindowWidth } from '@react-hook/window-size';
import SearchIcon from '@rsuite/icons/Search';
import api from '@services/api';
import { getFavoriteItems } from '@services/api/get-favorite-items';
import { formattedCurrency } from '@utils/formattedCurrency';
import { getBigInt } from '@utils/manageBigInt';
import { memo, useEffect, useRef, useState } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { FaShoppingBasket } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Input, InputGroup, SelectPicker } from 'rsuite';
function OrdersCreateOrUpdate() {
    var _this = this;
    var _a, _b, _c, _d, _e, _f;
    var refHeader = useRef(null);
    var _g = useCart(), itemsQuantity = _g.itemsQuantity, productsCart = _g.productsCart, clearAllProductToCart = _g.clearAllProductToCart, calculates = _g.calculates;
    var navigate = useNavigate();
    var handleErrors = useHandleError();
    var _h = useSearchParams(), query = _h[0], setQuery = _h[1];
    var categoryId = (_a = query.get('category_id')) !== null && _a !== void 0 ? _a : 'favorites';
    var term = (_b = query.get('term')) !== null && _b !== void 0 ? _b : '';
    var _j = useState(''), termSearch = _j[0], setTermSearch = _j[1];
    var resultTerm = useDebounce(termSearch);
    var action = useParams().action;
    var getValueQuery = useQueryParams().getValueQuery;
    var orderCode = (_c = getValueQuery('orderCode')) !== null && _c !== void 0 ? _c : null;
    var disclosureAddProductToOrder = useDisclosure();
    var disclosureBasket = useDisclosure();
    var storeSelected = useStore().storeSelected;
    var windowWidthSize = useWindowWidth();
    var _k = useMediaQuery(), tablePortrait = _k.tablePortrait, tableLands = _k.tableLands;
    useEffect(function () {
        if (resultTerm) {
            query.set('term', resultTerm);
            setQuery(query);
        }
        else {
            query.delete('term');
            setQuery(query);
        }
    }, [query, resultTerm, setQuery]);
    var _l = useFetchCatalogs(), catalogs = _l.data, isLoadingCatalogs = _l.isLoading, isSuccessCatalogs = _l.isSuccess;
    var _m = useFetchCurrenciesToStore(), currencies = _m.data, isLoadingCurrencies = _m.isLoading;
    var handleChangeCatalog = function (value) {
        var find = catalogs.find(function (catalog) { return catalog.uuid === value; });
        if (!find)
            return;
        onCatalogSelected(find);
    };
    var _o = useOrder(), catalogOrder = _o.catalog, spotOrder = _o.spot, onCatalogSelected = _o.onCatalogSelected, onSpotSelected = _o.onSpotSelected, onCurrencySelected = _o.onCurrencySelected, currencyOrder = _o.currency, onClearOrderContext = _o.onClearOrderContext, onClearPaymentData = _o.onClearPaymentData;
    var orderDetail = useGetOrderByOrderCode(orderCode, function (order) {
        onCurrencySelected(order.currency);
        onCatalogSelected(order.catalog);
        onSpotSelected(order.spot);
    }, {
        enabled: !!orderCode,
    }).data;
    var refreshOrderList = useFetchOrdersByOrderType().refetch;
    useEffect(function () {
        if (action === 'update' && !!orderCode) {
            onClearOrderContext();
            onClearPaymentData();
            clearAllProductToCart();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderCode, action]);
    var _p = useQuery("catalog-".concat(catalogOrder === null || catalogOrder === void 0 ? void 0 : catalogOrder.uuid, "-categories"), function () { return __awaiter(_this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/v1/catalogs/".concat(catalogOrder === null || catalogOrder === void 0 ? void 0 : catalogOrder.uuid, "/categories"))];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        enabled: (catalogOrder === null || catalogOrder === void 0 ? void 0 : catalogOrder.uuid) !== undefined && isSuccessCatalogs,
        onError: handleErrors,
    }), categories = _p.data, isLoadingCategories = _p.isLoading, isSuccessCategories = _p.isSuccess;
    var _q = useQuery({
        queryKey: ['favorite', 'items', storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid],
        queryFn: function () { return getFavoriteItems({ catalogId: catalogOrder === null || catalogOrder === void 0 ? void 0 : catalogOrder.uuid }); },
        enabled: !!(catalogOrder === null || catalogOrder === void 0 ? void 0 : catalogOrder.uuid),
    }), favoriteItems = _q.data, isLoadingFavoriteItems = _q.isLoading, refetchFavoriteItems = _q.refetch;
    var _r = useQuery([
        'catalogs',
        catalogOrder === null || catalogOrder === void 0 ? void 0 : catalogOrder.uuid,
        'category',
        categoryId,
        'items',
        'term',
        term,
    ], function () { return __awaiter(_this, void 0, void 0, function () {
        var response_1, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!term) return [3 /*break*/, 2];
                    return [4 /*yield*/, api.get("/v1/catalogs/".concat(catalogOrder === null || catalogOrder === void 0 ? void 0 : catalogOrder.uuid, "/items"), { params: { term: term } })];
                case 1:
                    response_1 = _a.sent();
                    return [2 /*return*/, response_1.data];
                case 2:
                    if (categoryId === 'favorites')
                        return [2 /*return*/, []];
                    return [4 /*yield*/, api.get("/v1/catalogs/".concat(catalogOrder === null || catalogOrder === void 0 ? void 0 : catalogOrder.uuid, "/items"), { params: { category_id: categoryId } })];
                case 3:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        enabled: (catalogOrder === null || catalogOrder === void 0 ? void 0 : catalogOrder.uuid) !== undefined &&
            isSuccessCatalogs &&
            isSuccessCategories,
        onError: handleErrors,
    }), items = _r.data, isLoadingItems = _r.isLoading, isFetchingItems = _r.isFetching, refetchItems = _r.refetch;
    function handleChangeCategory(value) {
        query.set('category_id', value);
        setQuery(query, { replace: true });
    }
    var mutationAddItemsToOrder = useAddItemsToOrderCreatedMutation({
        onSuccess: function () { return refreshOrderList(); },
    });
    function navigateToOrderList() {
        navigate("/orders?ot=".concat(getValueQuery('ot')));
    }
    function handleAddProductsToOrder(result) {
        return __awaiter(this, void 0, void 0, function () {
            var formattedItems;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(result === ENUM_RESULT_ALERT_BUTTON.ACCEPT)) return [3 /*break*/, 2];
                        formattedItems = productsCart.map(function (p) { return ({
                            id: p.uuid,
                            quantity: p.quantity,
                            comment: p.comment,
                        }); });
                        return [4 /*yield*/, mutationAddItemsToOrder.mutateAsync({
                                items: formattedItems,
                                orderCode: orderCode,
                            })];
                    case 1:
                        _a.sent();
                        clearAllProductToCart();
                        navigateToOrderList();
                        _a.label = 2;
                    case 2:
                        disclosureAddProductToOrder.onClose();
                        return [2 /*return*/];
                }
            });
        });
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, __assign({ direction: "row", sx: { flex: 1 } }, { children: [_jsxs(Box, __assign({ sx: { width: '100%' } }, { children: [_jsx(Stack, __assign({ ref: refHeader, direction: "row", alignItems: isDesktop ? 'flex-end' : 'flex-start', justifyContent: "space-between", sx: {
                                    p: 2,
                                    background: '#fff',
                                    display: 'sticky',
                                    top: 56,
                                } }, { children: _jsxs(Stack, __assign({ direction: isDesktop ? 'row' : 'column', spacing: 2, flex: 1, alignItems: "flex-end", justifyContent: "space-between", sx: { width: '100%' } }, { children: [_jsxs(Stack, __assign({ direction: "row", spacing: 1, sx: { width: '100%' } }, { children: [_jsx(IconButton, __assign({ size: "small", onClick: navigateToOrderList }, { children: _jsx(ArrowBackIosRoundedIcon, {}) })), _jsxs(InputGroup, { children: [_jsx(InputGroup.Addon, { children: _jsx(SearchIcon, {}) }), _jsx(Input, { type: "search", placeholder: "Buscar por nombre o sku", value: termSearch, onChange: function (e) { return setTermSearch(e); } })] }), _jsx(ButtonRefetching, { titleTooltip: "Actualizar listado de art\u00EDculos", onRefresh: function () {
                                                        return categoryId === 'favorites'
                                                            ? refetchFavoriteItems()
                                                            : refetchItems();
                                                    }, isFetching: isLoadingItems || isFetchingItems })] })), _jsxs(Stack, __assign({ direction: tablePortrait ? 'row' : 'column', spacing: 1, alignItems: isDesktop ? 'flex-end' : 'flex-start', justifyContent: isDesktop ? 'flex-end' : 'space-between', sx: { width: '100%' } }, { children: [!tableLands && _jsx(ContainerOrderTypes, {}), _jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1 }, { children: [_jsx(SelectPicker, { label: "Cat\u00E1logo", loading: isLoadingCatalogs, searchable: false, cleanable: false, value: catalogOrder === null || catalogOrder === void 0 ? void 0 : catalogOrder.uuid, defaultValue: catalogOrder === null || catalogOrder === void 0 ? void 0 : catalogOrder.uuid, data: catalogs === null || catalogs === void 0 ? void 0 : catalogs.map(function (catalog) { return (__assign(__assign({}, catalog), { label: catalog.name, value: catalog.uuid })); }), onSelect: function (value) { return handleChangeCatalog(value); }, disabled: !!orderCode || itemsQuantity > 0 }), _jsx(SelectPicker, { label: "Moneda", loading: isLoadingCurrencies, searchable: false, cleanable: false, value: currencyOrder === null || currencyOrder === void 0 ? void 0 : currencyOrder.uuid, defaultValue: currencyOrder === null || currencyOrder === void 0 ? void 0 : currencyOrder.uuid, data: currencies === null || currencies === void 0 ? void 0 : currencies.map(function (catalog) { return (__assign(__assign({}, catalog), { label: catalog.currency_country, value: catalog.uuid })); }), onSelect: function (_, item) { return onCurrencySelected(item); }, disabled: !!orderCode || itemsQuantity > 0 })] }))] }))] })) })), !term && (_jsx(Box, __assign({ sx: {
                                    width: '100%',
                                    maxWidth: tableLands
                                        ? windowWidthSize - 520
                                        : windowWidthSize - 2,
                                    bgcolor: 'background.paper',
                                } }, { children: _jsxs(Tabs, __assign({ variant: "scrollable", scrollButtons: true, allowScrollButtonsMobile: true, centered: true, sx: { alignItems: 'center' } }, { children: [isLoadingCategories && (_jsx(Stack, __assign({ direction: "row", alignItems: "flex-start" }, { children: Array.from({ length: 5 }).map(function (_, index) { return (_jsx(Skeleton, { height: 50, width: 100, sx: {
                                                    display: 'inline-block',
                                                    borderRadius: 5,
                                                    alignSelf: 'flex-start',
                                                    mb: 'auto',
                                                    mr: 0.5,
                                                } }, index)); }) }))), !isLoadingCategories && categories && (_jsxs(_Fragment, { children: [_jsx(Chip, { label: "Favoritos", variant: categoryId === 'favorites' ? 'filled' : 'outlined', color: categoryId === 'favorites' ? 'primary' : 'default', sx: {
                                                        mr: 0.5,
                                                        cursor: 'pointer',
                                                        fontWeight: categoryId === 'favorites' ? 'bold' : 500,
                                                        ':hover': {
                                                            borderColor: 'primary.main',
                                                            color: 'primary.main',
                                                        },
                                                    }, onClick: function () { return handleChangeCategory('favorites'); } }), categories === null || categories === void 0 ? void 0 : categories.map(function (category) { return (_jsx(Chip, { variant: categoryId === category.uuid ? 'filled' : 'outlined', color: categoryId === category.uuid ? 'primary' : 'default', label: category.name, sx: {
                                                        mr: 0.5,
                                                        cursor: 'pointer',
                                                        fontWeight: categoryId === category.uuid ? 'bold' : 500,
                                                        ':hover': {
                                                            borderColor: 'primary.main',
                                                            color: 'primary.main',
                                                        },
                                                    }, onClick: function () { return handleChangeCategory(category.uuid); } }, category.uuid)); })] }))] })) }))), _jsxs(Stack, __assign({ spacing: 1.5 }, { children: [_jsx(AlertTileVerifySpotHasOpenCashier, {}), _jsx(AlertTileOfCloseStoreSelected, {}), _jsx(AlertTileWithoutCatalogs, {}), _jsx(AlertTileWithoutCurrencies, {}), _jsx(AlertTileWithoutSpotOfMember, {})] })), _jsxs(Stack, __assign({ direction: "row", gap: 2, flexWrap: "wrap", sx: {
                                    maxHeight: "calc(100vh - ".concat(((_d = refHeader.current) === null || _d === void 0 ? void 0 : _d.clientHeight) + 48, "px)"),
                                    overflowY: 'auto',
                                    p: isMobile ? 0.5 : 2,
                                    pointerEvents: ((_e = spotOrder === null || spotOrder === void 0 ? void 0 : spotOrder.cashiers) === null || _e === void 0 ? void 0 : _e.length) === 0 ? 'none' : 'auto',
                                } }, { children: [(isLoadingItems || isLoadingFavoriteItems) &&
                                        Array.from({ length: 5 }).map(function (_, i) { return (_jsx(CardProductLoading, {}, i)); }), !isLoadingFavoriteItems &&
                                        favoriteItems &&
                                        categoryId === 'favorites' &&
                                        favoriteItems.items.map(function (item) { return (_jsx(CardProduct, { item: item, isFavorite: true }, item.uuid)); }), !isLoadingItems &&
                                        categoryId !== 'favorites' &&
                                        (items === null || items === void 0 ? void 0 : items.map(function (item) { return (_jsx(CardProduct, { item: item, isFavorite: favoriteItems === null || favoriteItems === void 0 ? void 0 : favoriteItems.items.map(function (fav) { return fav.uuid; }).includes(item.uuid) }, item.uuid)); }))] }))] })), action === 'create' && (_jsx(_Fragment, { children: tableLands ? (_jsx(Box, __assign({ sx: {
                                background: function (_a) {
                                    var palette = _a.palette;
                                    return palette.background.paper;
                                },
                                width: '100%',
                                minWidth: 450,
                                maxWidth: 600,
                                boxShadow: '-6px 0px 10px #e6e6e6',
                            } }, { children: _jsx(CreateOrderContainer, { onSuccessCreateOrder: function () {
                                    refetchItems();
                                } }) }))) : (_jsx(DrawerComponent.DrawerComponentSwipeable, __assign({ open: disclosureBasket.isOpen, onClose: disclosureBasket.onClose, onOpen: disclosureBasket.onOpen, anchor: "bottom" }, { children: _jsx(CreateOrderContainer, { onSuccessCreateOrder: function () {
                                    refetchItems();
                                } }) }))) }))] })), action === 'create' && !tableLands && (_jsx(Fab, __assign({ color: "primary", "aria-label": "add", size: "small", sx: {
                    position: 'absolute',
                    bottom: 20,
                    left: 20,
                    width: 'fit-content',
                    px: 2,
                    borderRadius: 30,
                    height: 60,
                }, onClick: disclosureBasket.onOpen }, { children: _jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 2 }, { children: [_jsx(FaShoppingBasket, { size: 20 }), _jsx(Typography, __assign({ fontWeight: "bold", fontSize: 18 }, { children: itemsQuantity }))] })) }))), action === 'update' ? (_jsx(Fab, __assign({ color: "primary", "aria-label": "add", size: "small", sx: {
                    position: 'absolute',
                    bottom: 20,
                    right: '50%',
                    transform: 'translateX(50%)',
                    width: 'fit-content',
                    px: 2,
                    borderRadius: 5,
                    height: 'auto',
                }, disabled: itemsQuantity === 0, onClick: disclosureAddProductToOrder.onOpen }, { children: itemsQuantity === 0 ? ('No tiene productos seleccionados.') : (_jsxs(Stack, { children: [_jsx(Typography, __assign({ variant: "button" }, { children: "".concat(itemsQuantity, " productos seleccionados. ").concat(calculates.total.formatted) })), _jsx(Typography, __assign({ variant: "button" }, { children: "Nuevo total: ".concat(formattedCurrency({
                                currency: orderDetail === null || orderDetail === void 0 ? void 0 : orderDetail.currency,
                                amount: getBigInt((orderDetail === null || orderDetail === void 0 ? void 0 : orderDetail.total_amount) + calculates.total.number),
                            })) }))] })) }))) : null, _jsxs(AlertComponent, __assign({ title: "Agregar productos", description: "Seguro que quieres agregar los productos a la order #".concat(orderCode), isOpen: disclosureAddProductToOrder.isOpen, onClose: disclosureAddProductToOrder.onClose, onResult: handleAddProductsToOrder, acceptedText: "Agregar productos", isLoading: mutationAddItemsToOrder.isLoading }, { children: [_jsx(List, { children: productsCart.map(function (pc) { return (_jsx(ListItem, __assign({ sx: {
                                borderBottom: function (_a) {
                                    var palette = _a.palette;
                                    return "1px solid ".concat(palette.divider);
                                },
                            } }, { children: _jsxs(Stack, __assign({ width: "100%", direction: "row", alignItems: "center", justifyContent: "space-between" }, { children: [_jsxs(Typography, __assign({ variant: "button" }, { children: [pc.quantity, "x ", pc.name] })), _jsx(Typography, __assign({ fontWeight: 500 }, { children: formattedCurrency({
                                            amount: getBigInt(pc.price.price * pc.quantity),
                                            currency: currencyOrder,
                                        }) }))] })) }), pc.auxID)); }) }), _jsxs(Stack, __assign({ direction: "row", spacing: 1, alignItems: "center", justifyContent: "space-between", p: 4 }, { children: [_jsx(Typography, __assign({ variant: "button" }, { children: "Nuevo monto de la orden:" })), _jsx(Typography, __assign({ fontWeight: 500, fontSize: 20 }, { children: formattedCurrency({
                                    currency: orderDetail === null || orderDetail === void 0 ? void 0 : orderDetail.currency,
                                    amount: getBigInt((orderDetail === null || orderDetail === void 0 ? void 0 : orderDetail.total_amount) + ((_f = calculates.total) === null || _f === void 0 ? void 0 : _f.number)),
                                }) }))] }))] }))] }));
}
export default memo(OrdersCreateOrUpdate);
