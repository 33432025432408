var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Divider, Stack } from 'rsuite';
import Modal from 'rsuite/Modal';
import ModalBody from 'rsuite/esm/Modal/ModalBody';
import ModalFooter from 'rsuite/esm/Modal/ModalFooter';
function ModalComponent(_a) {
    var onClose = _a.onClose, open = _a.open, title = _a.title, children = _a.children, actions = _a.actions, closeButton = _a.closeButton, rest = __rest(_a, ["onClose", "open", "title", "children", "actions", "closeButton"]);
    return (_jsxs(Modal, __assign({ open: open, onClose: onClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", style: {
            zIndex: 9999,
        }, backdropStyle: { zIndex: 9998 } }, rest, { children: [_jsx(Modal.Header, __assign({ closeButton: closeButton }, { children: title && (_jsx(Modal.Title, __assign({ style: { fontWeight: 500 } }, { children: title }))) })), _jsx(Divider, {}), _jsx(ModalBody, { children: children }), actions && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(ModalFooter, { children: _jsx(Stack, __assign({ direction: "row", alignItems: "center", spacing: 10, justifyContent: "flex-end" }, { children: actions })) })] }))] })));
}
export default memo(ModalComponent);
