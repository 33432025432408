var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import { getBigInt } from '@utils/manageBigInt';
import { memo, useEffect, useState } from 'react';
import { Input } from 'rsuite';
import { useCart } from '../../context/Cart';
import { useOrder } from '../../context/Order';
import useDebounce from '../../hooks/useDebounce';
import { BOX_PRODUCT_PLACEHOLDER } from '../../utils/constants';
import { formattedCurrency } from '../../utils/formattedCurrency';
function CardProductBasketComponent(_a) {
    var _b, _c;
    var item = _a.item;
    var currencyOrder = useOrder().currency;
    var _d = useCart(), addProductToCart = _d.addProductToCart, addCommentToItem = _d.addCommentToItem;
    var _e = useState((_b = item.comment) !== null && _b !== void 0 ? _b : ''), comment = _e[0], setComment = _e[1];
    var commentDone = useDebounce(comment);
    useEffect(function () {
        addCommentToItem(item.auxID, commentDone);
    }, [commentDone]);
    return (_jsxs(Stack, __assign({ direction: "row", sx: { width: '100%' }, spacing: 1, alignItems: "center" }, { children: [_jsxs(Stack, __assign({ spacing: 1, sx: { width: '100%' } }, { children: [_jsxs(Stack, __assign({ direction: "row", spacing: 1 }, { children: [_jsx(Avatar, { src: ((_c = item.images) === null || _c === void 0 ? void 0 : _c.length) > 0
                                    ? item.images[0].url
                                    : BOX_PRODUCT_PLACEHOLDER, sx: { width: 35, height: 35 } }), _jsxs(Stack, __assign({ direction: "row", spacing: 2, alignItems: "center", justifyContent: "space-between", sx: { width: '100%' } }, { children: [_jsxs(Typography, __assign({ variant: "h6", fontSize: 17 }, { children: [item.quantity, "x ", item.name] })), _jsx(Typography, __assign({ variant: "h6", fontSize: 17 }, { children: formattedCurrency({
                                            amount: getBigInt(item.price.price * item.quantity),
                                            currency: currencyOrder,
                                        }) }))] }))] })), _jsx(Input, { size: "sm", placeholder: "Escriba alg\u00FAn comentario", value: comment !== null && comment !== void 0 ? comment : '', defaultValue: comment !== null && comment !== void 0 ? comment : '', onChange: function (value) { return setComment(value); } })] })), _jsxs(Stack, __assign({ spacing: 1 }, { children: [_jsx(IconButton, __assign({ onClick: function () {
                            return addProductToCart(__assign(__assign({}, item), { quantity: item.quantity + 1 }));
                        } }, { children: _jsx(AddRoundedIcon, {}) })), _jsx(IconButton, __assign({ onClick: function () {
                            return addProductToCart(__assign(__assign({}, item), { quantity: item.quantity - 1 }));
                        } }, { children: _jsx(RemoveRoundedIcon, {}) }))] })), _jsx(IconButton, __assign({ color: "error", onClick: function () { return addProductToCart(__assign(__assign({}, item), { quantity: 0 })); } }, { children: _jsx(DeleteRoundedIcon, {}) }))] })));
}
export default memo(CardProductBasketComponent);
