var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { Message } from 'rsuite';
import useFetchSpotsOfMember from '../../../hooks/fetch/useFetchSpotsOfMember';
function AlertTileWithoutSpotOfMember() {
    var data = useFetchSpotsOfMember().data;
    return (_jsx(_Fragment, { children: (data === null || data === void 0 ? void 0 : data.length) === 0 && (_jsx(_Fragment, { children: _jsxs(Message, __assign({ showIcon: true, type: "warning" }, { children: [_jsx("strong", { children: "Advertencia!" }), " No tienes ninguna zona asignada.", ' ', _jsx("br", {}), "Por favor contacte a un ", _jsx("b", { children: "Administrador del sistema" })] })) })) }));
}
export default memo(AlertTileWithoutSpotOfMember);
