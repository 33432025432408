var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useDebounce from '@hooks/useDebounce';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { Button, InputNumber } from 'rsuite';
export function ButtonAddAndLess(_a) {
    var quantity = _a.quantity, onLess = _a.onLess, onAdd = _a.onAdd, disableLess = _a.disableLess, disableAdd = _a.disableAdd, onChangeValue = _a.onChangeValue;
    var _b = useState(quantity), quantityInput = _b[0], setQuantityInput = _b[1];
    var quantityDebounce = useDebounce(quantityInput);
    useEffect(function () {
        if (!!onChangeValue && quantityDebounce > 0) {
            onChangeValue(quantityDebounce);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quantityDebounce]);
    useEffect(function () {
        setQuantityInput(quantity);
    }, [quantity]);
    return (_jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1 }, { children: [_jsx(Button, __assign({ appearance: "subtle", onClick: onLess, disabled: disableLess }, { children: quantity === 1 ? (_jsx(DeleteRoundedIcon, { color: "error" })) : (_jsx(RemoveRoundedIcon, { color: "primary" })) })), _jsx(InputNumber, { size: "md", inputMode: "numeric", value: quantityInput, defaultValue: quantityInput, style: { maxWidth: 100 }, scrollable: false, onChange: function (val) {
                    !!onChangeValue && setQuantityInput(+val);
                } }), _jsx(Button, __assign({ appearance: "primary", onClick: onAdd, style: {
                    width: 40,
                    height: 40,
                    borderRadius: 100,
                    backgroundColor: 'primary.main',
                    transition: 'ease 0.2s',
                    flexShrink: 0,
                }, disabled: disableAdd }, { children: _jsx(AddRoundedIcon, { sx: { color: 'white' } }) }))] })));
}
