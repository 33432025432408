import axios from 'axios';
import config from 'config';
import { signOut } from '../context/Auth';
import { AUTHENTICATE_USER_TOKENS } from '../utils/storageConstants';
var isRefreshing = false;
var failedRequestsQueue = [];
var api = axios.create({
    baseURL: config.apiUrl,
    headers: {
        Authorization: "Bearer ".concat(getAccessToken()),
    },
});
// if (config.env === 'development') {
//   api.interceptors.request.use(async (config) => {
//     await new Promise((resolve) => setTimeout(resolve, 2000))
//     return config
//   })
// }
// Función para obtener el token de acceso del almacenamiento local
function getAccessToken() {
    var tokens = localStorage.getItem(AUTHENTICATE_USER_TOKENS);
    var parseTokens = JSON.parse(tokens || '{}');
    return parseTokens.token || '';
}
// Interceptores de respuesta para manejar el token expirado
api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    var _a, _b, _c, _d;
    var originalRequest = error.config;
    if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 &&
        ((_b = error.response.data) === null || _b === void 0 ? void 0 : _b.error_code) === 'token.expired') {
        if (!isRefreshing) {
            isRefreshing = true;
            var refreshToken = getRefreshToken();
            if (!refreshToken) {
                return Promise.reject(error);
            }
            return refreshAccessToken(refreshToken)
                .then(function (newToken) {
                retryFailedRequests(newToken);
                originalRequest.headers.Authorization = "Bearer ".concat(newToken);
                return axios(originalRequest);
            })
                .catch(function (refreshError) {
                return Promise.reject(refreshError);
            })
                .finally(function () {
                isRefreshing = false;
            });
        }
        else {
            // Si ya hay una solicitud de actualización en curso, añadir la solicitud fallida a la cola
            return new Promise(function (resolve, reject) {
                failedRequestsQueue.push({
                    onSuccess: function (token) {
                        originalRequest.headers.Authorization = "Bearer ".concat(token);
                        resolve(axios(originalRequest));
                    },
                    onFailure: function (err) {
                        reject(err);
                    },
                });
            });
        }
    }
    if (((_c = error.response) === null || _c === void 0 ? void 0 : _c.status) === 401 &&
        ((_d = error.response.data) === null || _d === void 0 ? void 0 : _d.error_code) !== 'token.expired') {
        signOut();
    }
    return Promise.reject(error);
});
// Función para obtener el token de actualización del almacenamiento local
function getRefreshToken() {
    var tokens = localStorage.getItem(AUTHENTICATE_USER_TOKENS);
    var parseTokens = JSON.parse(tokens || '{}');
    return parseTokens.token_refresh || '';
}
// Función para actualizar el token de acceso
function refreshAccessToken(refreshToken) {
    return api
        .post("/v1/sessions/refresh-token/".concat(refreshToken))
        .then(function (response) {
        var _a = response.data, newRefreshToken = _a.token_refresh, token = _a.token;
        localStorage.setItem(AUTHENTICATE_USER_TOKENS, JSON.stringify({ token_refresh: newRefreshToken, token: token }));
        api.defaults.headers.common.Authorization = "Bearer ".concat(token);
        return token;
    });
}
// Función para reintentar las solicitudes fallidas
function retryFailedRequests(token) {
    failedRequestsQueue.forEach(function (request) { return request.onSuccess(token); });
    failedRequestsQueue = [];
}
export default api;
