var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar } from '@mui/material';
import { memo } from 'react';
function stringToColor(string) {
    var hash = 0;
    var i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < (string === null || string === void 0 ? void 0 : string.length); i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    var color = '#';
    for (i = 0; i < 3; i += 1) {
        var value = (hash >> (i * 8)) & 0xff;
        color += "00".concat(value.toString(16)).slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
}
function CircleAvatarComponent(_a) {
    var _b = _a.size, size = _b === void 0 ? 40 : _b, name = _a.name, url = _a.url;
    var setting = {
        background: stringToColor(name),
        width: size,
        height: size,
    };
    return (_jsxs(_Fragment, { children: [url && _jsx(Avatar, { src: url, sx: setting }), !url && _jsx(Avatar, __assign({ sx: setting }, { children: name.charAt(0) }))] }));
}
export default memo(CircleAvatarComponent);
