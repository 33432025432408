export var PREFIX = '@BIZCOMM';
export var THEME = "".concat(PREFIX, ":THEME");
export var IS_DARK = "".concat(PREFIX, ":IS_DARK");
export var IS_OPEN_STORE = "".concat(PREFIX, ":IS_OPEN_STORE");
export var AUTHENTICATE_USER_DATA = "".concat(PREFIX, ":AUTHENTICATE_USER_DATA");
export var AUTHENTICATE_USER_ROLES = "".concat(PREFIX, ":AUTHENTICATE_USER_ROLE");
export var AUTHENTICATE_USER_PERMISSIONS = "".concat(PREFIX, ":AUTHENTICATE_USER_PERMISSIONS");
export var AUTHENTICATE_USER_STORES = "".concat(PREFIX, ":AUTHENTICATE_USER_STORES");
export var AUTHENTICATE_USER_TOKENS = "".concat(PREFIX, ":AUTHENTICATE_USER_TOKENS");
export var STORE_SELECTED = "".concat(PREFIX, ":STORE_SELECTED");
export var STORE_DISTRIBUTION_CHANNELS = "".concat(PREFIX, ":STORE_DISTRIBUTION_CHANNELS");
export var STORE_CONFIGURATIONS = "".concat(PREFIX, ":STORE_CONFIGURATIONS");
export var CREATE_ORDER_CURRENCY_SELECTED = "".concat(PREFIX, ":CREATE_ORDER_CURRENCY_SELECTED");
export var CREATE_ORDER_SPOT_SELECTED = "".concat(PREFIX, ":CREATE_ORDER_SPOT_SELECTED");
export var CREATE_ORDER_CATALOG_SELECTED = "".concat(PREFIX, ":CREATE_ORDER_CATALOG_SELECTED");
export var CART_STORAGE = "".concat(PREFIX, ":CART_STORAGE:");
