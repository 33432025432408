var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { rem } from 'polished';
import styled, { css } from 'styled-components';
export var Label = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n\n  font-size: 1rem;\n  font-family: Poppins !important;\n  display: block;\n  font-weight: 500;\n\n  border-radius: 1.5rem;\n"], ["\n  width: 100%;\n\n  font-size: 1rem;\n  font-family: Poppins !important;\n  display: block;\n  font-weight: 500;\n\n  border-radius: 1.5rem;\n"])));
export var Container = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n\n  ", "\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: ", ";\n\n  border: ", " dashed #acb8c7;\n  border-radius: ", ";\n  font-family: Poppins;\n\n  ", "\n\n  cursor: pointer;\n\n  ", ";\n\n  p {\n    text-align: center;\n\n    &.acceptedFiles {\n    }\n\n    &.rejectedFiles {\n    }\n  }\n\n  > em {\n    font-size: ", ";\n    opacity: 0.5;\n    text-align: center;\n  }\n"], ["\n  width: 100%;\n  height: 100%;\n\n  ", "\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: ", ";\n\n  border: ", " dashed #acb8c7;\n  border-radius: ", ";\n  font-family: Poppins;\n\n  ", "\n\n  cursor: pointer;\n\n  ", ";\n\n  p {\n    text-align: center;\n\n    &.acceptedFiles {\n    }\n\n    &.rejectedFiles {\n    }\n  }\n\n  > em {\n    font-size: ", ";\n    opacity: 0.5;\n    text-align: center;\n  }\n"])), function (_a) {
    var heightBox = _a.heightBox;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    min-height: ", ";\n  "], ["\n    min-height: ", ";\n  "])), rem(heightBox !== null && heightBox !== void 0 ? heightBox : '170px'));
}, rem('5px'), rem('2px'), rem('30px'), function (_a) {
    var hasError = _a.hasError, theme = _a.theme;
    return hasError && css(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
}, function (_a) {
    var hasLabel = _a.hasLabel;
    return hasLabel && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      margin-top: 0.5rem;\n    "], ["\n      margin-top: 0.5rem;\n    "])));
}, rem('12px'));
export var ContainerFiles = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n"])));
export var ContainerFile = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  width: 80px;\n\n  > p {\n    font-size: ", ";\n    display: -webkit-box;\n    max-width: 200px;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    opacity: 0.5;\n    line-height: 13px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  width: 80px;\n\n  > p {\n    font-size: ", ";\n    display: -webkit-box;\n    max-width: 200px;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    opacity: 0.5;\n    line-height: 13px;\n  }\n"])), rem('12px'));
export var IconFile = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  opacity: 0.5;\n  margin-bottom: 0.5rem;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  opacity: 0.5;\n  margin-bottom: 0.5rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
