var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Container, Drawer, SwipeableDrawer, } from '@mui/material';
import { useWindowWidth } from '@react-hook/window-size';
function WrapperDraw(_a) {
    var children = _a.children, _b = _a.width, width = _b === void 0 ? 500 : _b, _c = _a.padding, padding = _c === void 0 ? 0 : _c;
    var widthSize = useWindowWidth();
    return (_jsx(Container, __assign({ maxWidth: "xl", sx: { width: widthSize >= width ? width : widthSize, pt: 4, padding: padding } }, { children: children })));
}
function DrawerComponentNormal(_a) {
    var children = _a.children, onClose = _a.onClose, open = _a.open, _b = _a.anchor, anchor = _b === void 0 ? 'right' : _b, width = _a.width;
    return (_jsx(Drawer, __assign({ onClose: onClose, open: open, anchor: anchor }, { children: _jsx(WrapperDraw, __assign({ width: width }, { children: children })) })));
}
function DrawerComponent(_a) {
    var children = _a.children, onClose = _a.onClose, onOpen = _a.onOpen, open = _a.open, _b = _a.anchor, anchor = _b === void 0 ? 'right' : _b, width = _a.width, paddingWrapper = _a.paddingWrapper, rest = __rest(_a, ["children", "onClose", "onOpen", "open", "anchor", "width", "paddingWrapper"]);
    var iOS = typeof navigator !== 'undefined' &&
        /iPad|iPhone|iPod/.test(navigator.userAgent);
    return (_jsx(SwipeableDrawer, __assign({ onOpen: onOpen, onClose: onClose, open: open, disableBackdropTransition: !iOS, disableDiscovery: iOS, anchor: anchor }, rest, { children: _jsx(WrapperDraw, __assign({ width: width, padding: paddingWrapper }, { children: children })) })));
}
DrawerComponent.DrawerComponentSwipeable = DrawerComponent;
DrawerComponent.DrawerComponentNormal = DrawerComponentNormal;
export default DrawerComponent;
