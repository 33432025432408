import { useToast } from './useToast';
export default function useHandleError() {
    var toast = useToast();
    return function handleError(err) {
        // eslint-disable-next-line no-console
        console.log({ err: err, asd: err.response });
        if (err && err.response) {
            var _a = err.response.data, message = _a.message, status = _a.status;
            if (err.response.status !== 500) {
                toast.warning({
                    description: "".concat(message),
                });
                return;
            }
            toast.error({
                description: message,
            });
        }
    };
}
