var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import useDisclosure from '@hooks/useDisclosure';
import { createContext, useCallback, useContext, useMemo, useState, } from 'react';
import usePersistedState from '../hooks/usePersistedState';
import { AUTHENTICATE_USER_STORES, STORE_CONFIGURATIONS, STORE_DISTRIBUTION_CHANNELS, STORE_SELECTED, } from '../utils/storageConstants';
var StoreContext = createContext({});
var StoreProvider = function (_a) {
    var children = _a.children;
    var disclosureSideMenu = useDisclosure();
    var _b = usePersistedState(STORE_SELECTED, null), storeSelected = _b[0], setStoreSelected = _b[1];
    var _c = usePersistedState(STORE_CONFIGURATIONS, null), configurations = _c[0], setConfigurations = _c[1];
    // TODO: llevar a Auth esto
    var _d = usePersistedState(AUTHENTICATE_USER_STORES, []), stores = _d[0], setStores = _d[1];
    var _e = useState([]), paymentMethods = _e[0], setPaymentMethods = _e[1];
    var _f = usePersistedState(STORE_DISTRIBUTION_CHANNELS, []), distributionChannels = _f[0], setDistributionChannels = _f[1];
    var _g = useState([]), orderTypes = _g[0], setOrderTypes = _g[1];
    var _h = useState([]), currencies = _h[0], setCurrencies = _h[1];
    var _j = useState([]), catalogs = _j[0], setCatalogs = _j[1];
    var setStoresData = useCallback(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setStores(data);
            return [2 /*return*/];
        });
    }); }, []);
    var onSetStoreConfiguration = useCallback(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setConfigurations(data);
            return [2 /*return*/];
        });
    }); }, []);
    var setStoreSelectedData = useCallback(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setStoreSelected(data);
            return [2 /*return*/];
        });
    }); }, []);
    var handleStoreSelect = useCallback(function (data, options) {
        if ((storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.value) !== (data === null || data === void 0 ? void 0 : data.value) || (options === null || options === void 0 ? void 0 : options.force)) {
            // queryClient.invalidateQueries();
            setStoreSelected(data);
        }
    }, [storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.value]);
    var setPaymentMethodsOfStoreData = useCallback(function (data) {
        setPaymentMethods(data);
    }, []);
    var setOrderTypesOfStoreData = useCallback(function (data) {
        setOrderTypes(data);
    }, []);
    var setCurrenciesOfStoreData = useCallback(function (data) {
        setCurrencies(data);
    }, []);
    var setCatalogsOfStoreData = useCallback(function (data) {
        setCatalogs(data);
    }, []);
    var setDistributionChannelsOfStoreData = useCallback(function (data) {
        setDistributionChannels(data);
    }, []);
    var slugsOfOrderTypesOfStore = useMemo(function () {
        return orderTypes.map(function (or) { var _a; return (_a = or.order_type) === null || _a === void 0 ? void 0 : _a.slug; });
    }, [orderTypes]);
    var cleanContextStore = useCallback(function () {
        setStoreSelected(null);
        setStores([]);
        setOrderTypes([]);
        setCurrencies([]);
        setPaymentMethods([]);
        setCatalogs([]);
        setDistributionChannels([]);
    }, []);
    return (_jsx(StoreContext.Provider, __assign({ value: {
            stores: stores,
            storeSelected: storeSelected,
            orderTypes: orderTypes,
            currencies: currencies,
            catalogs: catalogs,
            paymentMethods: paymentMethods,
            slugsOfOrderTypesOfStore: slugsOfOrderTypesOfStore,
            distributionChannels: distributionChannels,
            disclosureSideMenu: disclosureSideMenu,
            configurations: configurations,
            setStoresData: setStoresData,
            setStoreSelectedData: setStoreSelectedData,
            setOrderTypesOfStoreData: setOrderTypesOfStoreData,
            setCurrenciesOfStoreData: setCurrenciesOfStoreData,
            setPaymentMethodsOfStoreData: setPaymentMethodsOfStoreData,
            setCatalogsOfStoreData: setCatalogsOfStoreData,
            setDistributionChannelsOfStoreData: setDistributionChannelsOfStoreData,
            handleStoreSelect: handleStoreSelect,
            cleanContextStore: cleanContextStore,
            onSetStoreConfiguration: onSetStoreConfiguration,
        } }, { children: children })));
};
function useStore() {
    var context = useContext(StoreContext);
    if (!context) {
        throw new Error('useStore must be used within a StoreProvider');
    }
    return context;
}
export { StoreProvider, useStore };
