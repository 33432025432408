var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonAddAndLess } from '@components/ButtonAddAndLess';
import { Star, StarOutlineRounded } from '@mui/icons-material';
import { Backdrop, Box, Chip, IconButton, Stack, Typography, } from '@mui/material';
import { deleteFavoriteItem } from '@services/api/delete-favorite-item';
import { markAsFavoriteItem } from '@services/api/mark-as-favorite-item';
import { EnumInventoryPolicyItem } from '@services/products/types/IItem';
import { getBigInt } from '@utils/manageBigInt';
import { rem } from 'polished';
import { memo, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Button, Grid, Panel, Row, Text } from 'rsuite';
import { useCart } from '../../context/Cart';
import { useOrder } from '../../context/Order';
import calculatePercentPriceDiscount from '../../utils/calculatePercentPriceDiscount';
import { BOX_PRODUCT_PLACEHOLDER } from '../../utils/constants';
import { formattedCurrency } from '../../utils/formattedCurrency';
function CardProduct(_a) {
    var _this = this;
    var _b, _c, _d, _e, _f, _g;
    var item = _a.item, isFavorite = _a.isFavorite;
    var _h = useOrder(), currencyOrder = _h.currency, catalogOrder = _h.catalog;
    var _j = useCart(), addProductToCart = _j.addProductToCart, productsCart = _j.productsCart;
    var stock = (_c = (_b = item.stocks[0]) === null || _b === void 0 ? void 0 : _b.stock) !== null && _c !== void 0 ? _c : 0;
    var queryClient = useQueryClient();
    // Buscar producto para saber la cantidad de productos en el carrito
    var _k = useState(0), quantity = _k[0], setQuantity = _k[1];
    useEffect(function () {
        var productFind = productsCart.filter(function (pc) { return pc.uuid === item.uuid; });
        var resultQuantity = productFind.reduce(function (acc, item) { return acc + Number(item.quantity); }, 0);
        setQuantity(resultQuantity);
    }, [item.uuid, productsCart]);
    var priceForCurrency = useMemo(function () {
        var _a;
        if (item.prices.length === 0 || !currencyOrder)
            return null;
        return ((_a = item.prices.find(function (price) {
            var _a;
            return ((_a = price.store_currency) === null || _a === void 0 ? void 0 : _a.currency_uuid) === (currencyOrder === null || currencyOrder === void 0 ? void 0 : currencyOrder.uuid) &&
                price.catalog_uuid === (catalogOrder === null || catalogOrder === void 0 ? void 0 : catalogOrder.uuid);
        })) !== null && _a !== void 0 ? _a : null);
    }, [catalogOrder === null || catalogOrder === void 0 ? void 0 : catalogOrder.uuid, currencyOrder, item.prices]);
    var widthImage = rem(180);
    var heightImage = rem(180);
    var markAsFavoriteItemFn = useMutation({
        mutationFn: markAsFavoriteItem,
        onMutate: function () {
            var favoriteListCache = queryClient.getQueriesData([
                'favorite',
                'items',
            ]);
            favoriteListCache.forEach(function (_a) {
                var cacheKey = _a[0], cacheData = _a[1];
                if (!cacheData) {
                    return;
                }
                queryClient.setQueryData(cacheKey, {
                    items: __spreadArray(__spreadArray([], cacheData.items, true), [item], false),
                });
            });
        },
    }).mutateAsync;
    var deleteFavoriteItemFn = useMutation({
        mutationFn: deleteFavoriteItem,
        onMutate: function () {
            var favoriteListCache = queryClient.getQueriesData([
                'favorite',
                'items',
            ]);
            favoriteListCache.forEach(function (_a) {
                var cacheKey = _a[0], cacheData = _a[1];
                if (!cacheData) {
                    return;
                }
                queryClient.setQueryData(cacheKey, {
                    items: cacheData.items.filter(function (i) { return i.uuid !== item.uuid; }),
                });
            });
        },
    }).mutateAsync;
    return (_jsxs(Panel, __assign({ title: item.name, shaded: true, bordered: true, bodyFill: true, style: {
            width: '100%',
            maxWidth: widthImage,
            height: '100%',
            borderRadius: '10px',
            position: 'relative',
            opacity: !item.is_available ? 0.5 : 1,
            pointerEvents: !item.is_available ? 'none' : undefined,
            userSelect: !item.is_available ? 'none' : undefined,
        } }, { children: [item.is_available === false && (_jsx(Backdrop, __assign({ open: true, sx: {
                    position: 'absolute',
                    borderRadius: '10px',
                    backgroundColor: 'transparent',
                    zIndex: 1,
                } }, { children: _jsx(Typography, __assign({ variant: "button", fontWeight: 700, fontSize: 16 }, { children: "No disponible" })) }))), Number(priceForCurrency === null || priceForCurrency === void 0 ? void 0 : priceForCurrency.compare_to) > 0 && (_jsx(Chip, { label: calculatePercentPriceDiscount({
                    price: priceForCurrency === null || priceForCurrency === void 0 ? void 0 : priceForCurrency.price,
                    compare_to: priceForCurrency === null || priceForCurrency === void 0 ? void 0 : priceForCurrency.compare_to,
                }), color: "primary", size: "small", sx: {
                    position: 'absolute',
                    left: 0,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    fontWeight: 600,
                    fontSize: 12,
                    top: 10,
                    zIndex: 1,
                } })), _jsxs(Grid, __assign({ fluid: true, style: { justifyContent: 'center' } }, { children: [_jsx(Row, { children: _jsxs(Box, __assign({ width: widthImage, height: heightImage, sx: { position: 'relative' } }, { children: [_jsx(Box, { component: "img", width: widthImage, height: heightImage, src: ((_d = item.images) === null || _d === void 0 ? void 0 : _d.length) > 0
                                        ? item.images[0].url
                                        : BOX_PRODUCT_PLACEHOLDER, alt: ((_e = item.images) === null || _e === void 0 ? void 0 : _e.length) > 0 ? item.images[0].name : item.name, sx: {
                                        objectFit: 'contain',
                                        borderRadius: 2,
                                        position: 'relative',
                                    }, loading: "lazy" }), _jsx(IconButton, __assign({ sx: {
                                        position: 'absolute',
                                        top: rem(5),
                                        right: rem(5),
                                        marginLeft: 0,
                                        boxShadow: 2,
                                        background: 'white',
                                        ':hover': {
                                            background: '#e6e6e6',
                                        },
                                    }, onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                        var _a;
                                        return __generator(this, function (_b) {
                                            switch (_b.label) {
                                                case 0:
                                                    if (!isFavorite) return [3 /*break*/, 2];
                                                    return [4 /*yield*/, deleteFavoriteItemFn({ itemId: item.uuid })];
                                                case 1:
                                                    _a = _b.sent();
                                                    return [3 /*break*/, 4];
                                                case 2: return [4 /*yield*/, markAsFavoriteItemFn({ itemId: item.uuid })];
                                                case 3:
                                                    _a = _b.sent();
                                                    _b.label = 4;
                                                case 4: return [2 /*return*/, _a];
                                            }
                                        });
                                    }); } }, { children: isFavorite ? (_jsx(Star, { fontSize: "medium", color: "warning" })) : (_jsx(StarOutlineRounded, { fontSize: "medium" })) }))] })) }), _jsxs(Stack, __assign({ style: {
                            height: rem(210),
                            width: widthImage,
                            justifyContent: 'space-between',
                            paddingInline: rem(10),
                            paddingBlock: rem(15),
                        } }, { children: [_jsx(Row, { children: _jsx(Text, __assign({ muted: true, size: "sm" }, { children: item.sku })) }), _jsx(Row, { children: _jsx(Text, __assign({ size: "lg", weight: "regular", style: {
                                        lineHeight: rem(18),
                                        marginBottom: rem(12),
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        WebkitLineClamp: 2,
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        wordBreak: 'break-word',
                                    } }, { children: item.name })) }), _jsx(Row, { children: _jsx(Stack, __assign({ direction: "row", spacing: 1.5, alignItems: "center", justifyContent: "space-between", sx: { mb: 1, flex: 1 } }, { children: priceForCurrency ? (_jsx(_Fragment, { children: _jsxs(Stack, { children: [Number(priceForCurrency === null || priceForCurrency === void 0 ? void 0 : priceForCurrency.compare_to) > 0 && (_jsx(Typography, __assign({ variant: "overline", component: "span", fontSize: 14, sx: { textDecoration: 'line-through' }, lineHeight: 1.5 }, { children: formattedCurrency({
                                                        amount: getBigInt(priceForCurrency === null || priceForCurrency === void 0 ? void 0 : priceForCurrency.compare_to),
                                                        currency: currencyOrder,
                                                    }) }))), _jsx(Typography, __assign({ variant: "overline", fontSize: 19, fontWeight: "bold", lineHeight: 1 }, { children: formattedCurrency({
                                                        amount: getBigInt(priceForCurrency === null || priceForCurrency === void 0 ? void 0 : priceForCurrency.price),
                                                        currency: currencyOrder,
                                                    }) }))] }) })) : (_jsx(Typography, __assign({ variant: "overline", fontSize: 14, lineHeight: 1, color: "error" }, { children: "No tiene precio en la moneda seleccionada" }))) })) }), _jsx(Row, __assign({ style: { alignSelf: 'center' } }, { children: _jsxs(Typography, __assign({ variant: "caption", flexShrink: 0, textAlign: "right", color: "text.secondary" }, { children: ["Stock: ", (_g = (_f = item.stocks[0]) === null || _f === void 0 ? void 0 : _f.stock) !== null && _g !== void 0 ? _g : 0] })) })), _jsx(Row, __assign({ style: { width: '100%' } }, { children: priceForCurrency && (_jsx(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1 }, { children: quantity === 0 ? (_jsx(Button, __assign({ appearance: "primary", size: "sm", style: { borderRadius: 5, width: '100%' }, onClick: function () {
                                            return addProductToCart(__assign(__assign({}, item), { price: priceForCurrency, quantity: Number(item.increment) }));
                                        }, disabled: item.inventory_policy === EnumInventoryPolicyItem.CONTINUE
                                            ? false
                                            : stock === 0 }, { children: "A\u00F1adir al carrito" }))) : (_jsx(ButtonAddAndLess, { quantity: quantity, onLess: function () {
                                            addProductToCart(__assign(__assign({}, item), { price: priceForCurrency, quantity: quantity - Number(item.increment) }));
                                        }, onAdd: function () {
                                            addProductToCart(__assign(__assign({}, item), { price: priceForCurrency, quantity: quantity + Number(item.increment) }));
                                        }, onChangeValue: Number(item.increment) === 1
                                            ? function (q) {
                                                if (Number(item.increment) === 1) {
                                                    addProductToCart(__assign(__assign({}, item), { price: priceForCurrency, quantity: q }));
                                                }
                                            }
                                            : undefined, disableAdd: item.inventory_policy === EnumInventoryPolicyItem.CONTINUE
                                            ? false
                                            : stock === quantity })) }))) }))] }))] }))] })));
}
export default memo(CardProduct);
