import { useMemo } from 'react';
import { useAuth } from '../context/Auth';
import { useStore } from '../context/Store';
export function useCan(_a) {
    var _b;
    var permissions = _a.permissions, roles = _a.roles;
    var storeSelected = useStore().storeSelected;
    var _c = useAuth(), authPermissions = _c.permissions, user = _c.user, rolesAuth = _c.roles;
    var data = useMemo(function () {
        var _a;
        if (!storeSelected)
            return [];
        return (_a = authPermissions[storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid]) !== null && _a !== void 0 ? _a : [];
    }, [authPermissions]);
    // return function can(): boolean {
    if (roles === null || roles === void 0 ? void 0 : roles.includes('all'))
        return true;
    if (!user) {
        return false;
    }
    if (permissions && (permissions === null || permissions === void 0 ? void 0 : permissions.length) > 0) {
        var permissionsArray = Array.isArray(permissions)
            ? permissions
            : [permissions];
        var hasPermissions = permissionsArray.every(function (permission) {
            return data.includes(permission);
        });
        return hasPermissions;
    }
    if ((roles === null || roles === void 0 ? void 0 : roles.length) > 0) {
        return roles === null || roles === void 0 ? void 0 : roles.includes((_b = rolesAuth[storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid]) === null || _b === void 0 ? void 0 : _b.code_role);
    }
    return true;
}
export default useCan;
