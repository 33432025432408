var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import usePersistedState from '@hooks/usePersistedState';
import { getBigInt } from '@utils/manageBigInt';
import { CART_STORAGE } from '@utils/storageConstants';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { formattedCurrency } from '../utils/formattedCurrency';
import { useOrder } from './Order';
import { useStore } from './Store';
var CartContext = createContext({});
function CartProvider(_a) {
    var children = _a.children;
    var currencyOrder = useOrder().currency;
    var storeSelected = useStore().storeSelected;
    var _b = usePersistedState("".concat(CART_STORAGE).concat(storeSelected === null || storeSelected === void 0 ? void 0 : storeSelected.uuid), []), productsCart = _b[0], setProductsCart = _b[1];
    var _c = useState({}), calculates = _c[0], setCalculates = _c[1];
    var addProductToCart = useCallback(function (product) {
        var findIndexResult = productsCart.findIndex(function (item) { return item.uuid === product.uuid; });
        // const auxID = product?.has_toppings || product?.has_variants ?  uuidV4() : product.uuid;
        var auxID = product.uuid;
        if (findIndexResult === -1) {
            setProductsCart(function (state) { return __spreadArray(__spreadArray([], state, true), [__assign(__assign({}, product), { auxID: auxID })], false); });
        }
        else {
            var newProducts = new (Array.bind.apply(Array, __spreadArray([void 0], productsCart, false)))();
            if (product.quantity === 0) {
                newProducts.splice(findIndexResult, 1);
            }
            else {
                newProducts[findIndexResult] = __assign(__assign({}, newProducts[findIndexResult]), { quantity: product.quantity });
            }
            setProductsCart(newProducts);
        }
        if (window.navigator && window.navigator.vibrate) {
            window.navigator.vibrate(50);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productsCart]);
    var addCommentToItem = useCallback(function (auxID, comment) {
        var findIndexResult = productsCart.findIndex(function (item) { return item.auxID === auxID; });
        if (findIndexResult !== -1) {
            var newProducts = new (Array.bind.apply(Array, __spreadArray([void 0], productsCart, false)))();
            newProducts[findIndexResult] = __assign(__assign({}, newProducts[findIndexResult]), { comment: comment });
            setProductsCart(newProducts);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productsCart]);
    var clearAllProductToCart = useCallback(function () {
        setProductsCart([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeSelected, productsCart]);
    /**
     * @func onCalculateSummary => calculate summary total, subtotal, discount
     * @children onCalculateTotal. onCalculateSubtotal, onCalculateDiscount
     */
    var onCalculateTotal = useCallback(function (products) {
        if (currencyOrder) {
            var total = products.reduce(function (acc, product) { var _a; return acc + Number((_a = product.price) === null || _a === void 0 ? void 0 : _a.price) * Number(product.quantity); }, 0);
            var formatted = formattedCurrency({
                amount: getBigInt(total),
                currency: currencyOrder,
            });
            return { number: total, formatted: formatted };
        }
        return {};
    }, [currencyOrder]);
    var onCalculateSubtotal = useCallback(function (products) {
        if (currencyOrder) {
            var subtotal = products.reduce(function (acc, product) {
                var _a, _b, _c;
                return acc +
                    (Number((_a = product.price) === null || _a === void 0 ? void 0 : _a.compare_to) > 0
                        ? Number((_b = product.price) === null || _b === void 0 ? void 0 : _b.compare_to)
                        : Number((_c = product.price) === null || _c === void 0 ? void 0 : _c.price)) *
                        Number(product.quantity);
            }, 0);
            var formatted = formattedCurrency({
                amount: getBigInt(subtotal),
                currency: currencyOrder,
            });
            return { number: subtotal, formatted: formatted };
        }
        return {};
    }, [currencyOrder]);
    var onCalculateDiscount = useCallback(function (products) {
        if (currencyOrder) {
            var discount = products.reduce(function (acc, product) {
                var _a, _b, _c;
                return Number((_a = product.price) === null || _a === void 0 ? void 0 : _a.compare_to) > 0
                    ? acc +
                        (Number((_b = product.price) === null || _b === void 0 ? void 0 : _b.compare_to) -
                            Number((_c = product.price) === null || _c === void 0 ? void 0 : _c.price)) *
                            Number(product.quantity)
                    : acc;
            }, 0);
            var formatted = formattedCurrency({
                amount: getBigInt(discount),
                currency: currencyOrder,
            });
            return { number: discount, formatted: formatted };
        }
        return {};
    }, [currencyOrder]);
    var onCalculateSummary = useCallback(function () {
        var subtotal = onCalculateSubtotal(productsCart);
        var total = onCalculateTotal(productsCart);
        var discount = onCalculateDiscount(productsCart);
        setCalculates(function (state) { return (__assign(__assign({}, state), { total: total, subtotal: subtotal, discount: discount })); });
    }, [onCalculateSubtotal, productsCart, onCalculateTotal, onCalculateDiscount]);
    var itemsQuantity = useMemo(function () {
        return productsCart.reduce(function (acc, product) { return acc + product.quantity; }, 0);
    }, [productsCart]);
    useEffect(function () {
        onCalculateSummary();
    }, [onCalculateSummary, productsCart]);
    return (_jsx(CartContext.Provider, __assign({ value: {
            productsCart: productsCart,
            calculates: calculates,
            itemsQuantity: itemsQuantity,
            addProductToCart: addProductToCart,
            addCommentToItem: addCommentToItem,
            clearAllProductToCart: clearAllProductToCart,
        } }, { children: children })));
}
function useCart() {
    var context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
}
export { CartProvider, useCart };
