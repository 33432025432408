var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaRegFilePdf } from 'react-icons/fa';
import { Text } from 'rsuite';
import { theme } from '../../App';
import { Container, ContainerFile, ContainerFiles, IconFile, Label, } from './styles';
function DropZone(_a) {
    var label = _a.label, placeholder = _a.placeholder, helperText = _a.helperText, dropzoneOptions = _a.dropzoneOptions, error = _a.error, _b = _a.withPreview, withPreview = _b === void 0 ? true : _b, heightBox = _a.heightBox, onDragAcceptFiles = _a.onDragAcceptFiles, files = _a.files;
    var _c = useDropzone(__assign({ noClick: true, noKeyboard: true, onDrop: function (acceptedFiles) { return onDragAcceptFiles(acceptedFiles); } }, dropzoneOptions)), getRootProps = _c.getRootProps, getInputProps = _c.getInputProps, isDragActive = _c.isDragActive, isDragAccept = _c.isDragAccept, isDragReject = _c.isDragReject;
    function renderIcon(file) {
        switch (file === null || file === void 0 ? void 0 : file.type) {
            case 'application/pdf':
                return _jsx(FaRegFilePdf, { size: 40 });
            case 'image/jpeg':
            case 'image/png':
            case 'image/gif':
            case 'image/webp':
                return (_jsx("img", { style: {
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                    }, src: URL.createObjectURL(file), alt: file === null || file === void 0 ? void 0 : file.name }));
            default:
                break;
        }
    }
    return (_jsxs(Label, { children: [label, _jsxs(Container, __assign({}, getRootProps(), { hasLabel: !!label, hasError: !!error, heightBox: heightBox, style: { borderColor: theme.palette.primary.main } }, { children: [_jsx("input", __assign({}, getInputProps())), files !== null && (files === null || files === void 0 ? void 0 : files.length) > 0 && withPreview ? (_jsx(ContainerFiles, { children: files === null || files === void 0 ? void 0 : files.map(function (file) { return (_jsxs(ContainerFile, { children: [_jsx(IconFile, { children: renderIcon(file) }), _jsx("p", { children: file === null || file === void 0 ? void 0 : file.name })] }, file === null || file === void 0 ? void 0 : file.name)); }) })) : (_jsxs(_Fragment, { children: [isDragAccept && (_jsx("p", __assign({ className: "acceptedFiles" }, { children: "Todos los archivos ser\u00E1n aceptados." }))), isDragReject && (_jsx("p", __assign({ className: "rejectedFiles" }, { children: "Algunos archivos ser\u00E1n rechazados" }))), !isDragActive && (_jsx(Text, __assign({ weight: "medium" }, { children: placeholder !== null && placeholder !== void 0 ? placeholder : 'Click o arrastre los archivos aquí...' }))), helperText && _jsx("em", { children: helperText })] }))] }))] }));
}
export default memo(DropZone);
