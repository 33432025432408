import { ENUM_SLUG_DISTRIBUTION_CHANNEL } from '@services/distributionChannels/types/IDistributionChannel';
import { getBigInt } from './manageBigInt';
export function createAndIncrementPayments(_a) {
    var totalPending = _a.totalPending, paymentMethods = _a.paymentMethods, isShow = _a.isShow;
    if (isShow) {
        var paymentCount = 1;
        var factionalAmount_1 = totalPending.number / paymentCount;
        var now = new Date();
        var date_1 = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
        var formattedPayments = Array.from({ length: paymentCount }).map(function (_, index) { return ({
            id: index,
            chargedAmount: getBigInt(factionalAmount_1),
            paymentMethod: paymentMethods[ENUM_SLUG_DISTRIBUTION_CHANNEL.POINT_OF_SALE][0],
            enteredAmount: getBigInt(factionalAmount_1),
            chargedAmountWithoutFormat: factionalAmount_1,
            enteredAmountWithoutFormat: factionalAmount_1,
            tipAmountWithoutFormat: 0 * 100,
            tipAmount: 0,
            paymentDate: date_1.substring(0, 16),
        }); });
        return formattedPayments;
    }
    return undefined;
}
