var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Can from '@components/Can';
import ButtonComponent from '@components/Form/ButtonComponent';
import { SelectPickerStore } from '@components/Header/select-picker-store';
import { useStore } from '@context/Store';
import { WarehouseOutlined } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import { useWindowWidth } from '@react-hook/window-size';
import { rem } from 'polished';
import { isMobile } from 'react-device-detect';
import { BiSolidPurchaseTag } from 'react-icons/bi';
import { FaCashRegister } from 'react-icons/fa';
import { FiCheckSquare, FiLayers, FiSettings } from 'react-icons/fi';
import { TbReportAnalytics } from 'react-icons/tb';
import { useMatch, useNavigate } from 'react-router-dom';
import { Drawer } from 'rsuite';
export var optionSideMenu = [
    {
        title: 'Dashboard',
        path: '/',
        pathActive: '/',
        isEnd: true,
        icon: _jsx(FiLayers, { size: 15 }),
        rolesPermission: 'all',
    },
    {
        title: 'Órdenes',
        path: '/orders',
        pathActive: '/orders/*',
        icon: _jsx(FiCheckSquare, { size: 15 }),
        rolesPermission: [
            'seller',
            'super-administrator',
            'administrator',
            'cashier',
        ],
    },
    {
        title: 'Gestión de cajas',
        path: '/management-cashiers',
        pathActive: '/management-cashiers/*',
        icon: _jsx(FaCashRegister, { size: 15 }),
        rolesPermission: ['super-administrator', 'administrator', 'cashier'],
    },
    {
        title: 'Gestión de almacenes',
        path: '/management-warehouses',
        pathActive: '/management-warehouses/*',
        icon: _jsx(WarehouseOutlined, { fontSize: "small" }),
        rolesPermission: ['super-administrator', 'administrator'],
    },
    {
        title: 'Reporte de ventas',
        path: '/sales-report',
        pathActive: '/sales-report/*',
        icon: _jsx(TbReportAnalytics, { size: 15 }),
        rolesPermission: ['super-administrator', 'administrator'],
    },
    {
        title: 'Órdenes de compra',
        path: '/purchases/orders',
        pathActive: '/purchases/*',
        icon: _jsx(BiSolidPurchaseTag, { size: 15 }),
        rolesPermission: ['super-administrator', 'administrator'],
    },
    {
        title: 'Configuración',
        path: '/configurations',
        pathActive: '/configurations/*',
        icon: _jsx(FiSettings, { size: 15 }),
        permissions: ['configuration-page'],
    },
];
export function LeftSideMenu() {
    var disclosureSideMenu = useStore().disclosureSideMenu;
    var navigate = useNavigate();
    var widthSize = useWindowWidth();
    function activeButton(path, end) {
        if (end === void 0) { end = false; }
        return !!useMatch({ path: path, end: end });
    }
    return (_jsxs(Drawer, __assign({ onClose: disclosureSideMenu.onClose, onOpen: disclosureSideMenu.onOpen, open: disclosureSideMenu.isOpen, placement: "left", size: isMobile ? widthSize : 'xs' }, { children: [_jsx(Drawer.Header, { children: _jsx(SelectPickerStore, {}) }), _jsx(Drawer.Body, __assign({ style: { padding: rem(24) } }, { children: _jsx(Stack, __assign({ alignItems: "flex-start", spacing: 1.5, flex: 1 }, { children: optionSideMenu.map(function (option) { return (_jsx(Can, __assign({ permissions: option.permissions, roles: option.rolesPermission }, { children: _jsx(Tooltip, __assign({ title: option.title, placement: "right", arrow: true }, { children: _jsx(ButtonComponent, __assign({ fullWidth: true, variant: activeButton(option.pathActive, option.isEnd)
                                    ? 'contained'
                                    : 'text', sx: {
                                    marginRight: 'auto',
                                    px: 2,
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }, onClick: activeButton(option.pathActive, option.isEnd)
                                    ? function () {
                                        disclosureSideMenu.onClose();
                                    }
                                    : function () {
                                        navigate("".concat(option.path));
                                        disclosureSideMenu.onClose();
                                    } }, { children: _jsxs(Stack, __assign({ direction: "row", alignItems: "center", justifyItems: "flex-start", spacing: 2 }, { children: [option.icon, _jsx(Typography, __assign({ variant: "button", color: activeButton(option.pathActive, option.isEnd)
                                                ? 'white'
                                                : 'primary', sx: { textDecoration: 'none' } }, { children: option.title }))] })) })) })) }), option.path)); }) })) }))] })));
}
