import { useTheme } from '@mui/material/styles';
import useMediaQueryMaterial from '@mui/material/useMediaQuery';
export function useMediaQuery() {
    var theme = useTheme();
    var downSm = useMediaQueryMaterial(theme.breakpoints.down('sm'));
    var downMd = useMediaQueryMaterial(theme.breakpoints.down('md'));
    var tablePortrait = useMediaQueryMaterial(theme.breakpoints.up('sm'));
    var tableLands = useMediaQueryMaterial(theme.breakpoints.up('md'));
    return { downSm: downSm, downMd: downMd, tablePortrait: tablePortrait, tableLands: tableLands };
}
